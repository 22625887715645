@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

html,
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}
a {
  text-decoration: none !important;
}

html,
body {
  scroll-behavior: smooth;
}

a {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #e8effd inset !important;
  transition: #fff 5000s ease-in-out 0s !important;
}

.networklist img {
  max-width: 20px;
  margin-right: 5px;
}
.networklist .btn-success {
  color: #fff;
  border-radius: 0px;
  box-shadow: unset;
  border-color: #1e24b9;
}
.networklist .btn-success:hover {
  background-color: unset;
  border-color: unset;
}
.networklist .show > .btn-success.dropdown-toggle {
  background-color: unset;
  border-color: #1e24b9;
  box-shadow: unset !important;
  color: #fff;
}
.networklist .btn-success:focus {
  box-shadow: unset;
}
.networklist .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: unset;
}
.networklist .btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
}
.networklist .dropdown-menu {
  background-color: #090909;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 12px 8px;
  border-radius: 10px;
}
.networklist .dropdown-item {
  margin-bottom: 12px;
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #fff;
}
.networklist .dropdown-item:hover {
  background-color: #1e24b9;
}
.walletlist p,
label,
h5 {
  color: #fff;
  font-weight: 500;
}
.walletlist p a {
  color: #fff;
}

.walletlist p a:hover {
  color: #fff;
}
.blurimg {
  opacity: 0.4;
  cursor: not-allowed;
}
.netimg {
  width: 50px;
  height: 50px;
  aspect-ratio: 3/2;
  object-fit: contain;
  border-radius: 50%;
}
.netinput {
  position: absolute !important;
  right: 25px;
  bottom: 0;
}
.walletlist .form-check {
  position: unset;
}
.walletlist .form-check-input[type="checkbox"] {
  border-radius: 1.25em !important;
}
.walletlist .form-check .form-check-input:checked {
  background-color: #86e2ff !important;
  border: 1px solid #86e2ff !important;
}
.networkname {
  margin-top: 7px;
  font-size: 12px;
}
.metahover {
  cursor: pointer;
  transition: 0.5s;
}
.metahover:hover p {
  color: #1e24b9;
  transition: 0.5s;
}

/* INNER PAGE CSS */
.get-started-btn.link_wallet_new:hover {
  background: transparent linear-gradient(102deg, #f54200 30%, #f5ae00 77%) 0%
    0% no-repeat padding-box;
  color: #fff !important;
  /* border: 0px; */
  border: 1px solid #1e24b9;
}
.get-started-btn.link_wallet_new:hover .linea_icon {
  filter: unset !important;
}
.btn_black {
  font-size: 12px;
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: 0.5s;
  padding: 13px 30px;
  background: linear-gradient(180deg, #1e24b9, #1e24b9);
  color: #fff;
  border: 1px solid #fff;
  font-family: "Orbitron", sans-serif;
  font-weight: 700;

  border-radius: 0px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.btn_black:hover {
  color: #1e24b9 !important;
  background: #fff !important;
}

.btn_black_outline {
  font-size: 12px;
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: 0.5s;
  padding: 13px 30px;
  background: transparent;
  /* background: linear-gradient(180deg, #fff, #fff); */
  color: #fff;
  border: 1px solid #fff;
  font-family: "Orbitron", sans-serif;
  font-weight: 700;

  border-radius: 0px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.btn_black_outline:hover {
  color: #fff !important;
  background: linear-gradient(180deg, #fff, #fff) !important;
}
.get-started-btn {
  font-size: 12px;
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: 0.5s;
  padding: 16px 20px;
  background: #1e24b9;
  color: #fff;
  border: 1px solid #1e24b9;

  border-radius: 0px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.get-started-btn.border {
  border: 1px solid #fff !important;
}

.get-started-btn-border {
  font-size: 12px;
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: 0.5s;
  padding: 16px 20px;
  color: #fff;
  border: 1px solid #fff;
  background-size: 100% 100%;
  background-color: transparent;
  border-radius: 0px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.get-started-btn-border:hover {
  /* background: linear-gradient(180deg, #fa542c, #ed9a1f) !important; */
  background: #1e24b9;
  border: 1px solid #1e24b9;
  color: #fff;
}
.font_20 {
  font-size: 20px !important;
}
.btn_icon_grad {
  height: 40px;
  color: #fff !important;
}
/* .btn_square
{
  width: 40px;
  height: 40px;
} */
.get-started-btn.rounded_btn_wal {
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  padding: 2px !important;
}
.get-started-btn.rounded_btn_wal svg {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
}
.get-started-btn svg {
  color: #fff !important;
  fill: #fff !important;
}

.get-started-btn:hover {
  /* background-image: url("../images/btn_bg.png"); */
  background-size: 100% 100%;
  transition: 0.5s;
  filter: brightness(1.2);
  /* color: #1e24b9 !important; */
}
.live-btn:hover {
  color: #fff !important;
}
.get-started-btn:hover svg {
  /* fill:#1E24B9 !important; */
  fill: #fff !important;
}
/* ....................v.................. */
:root {
  --textblue: #16f9b2;
  --themeorange: #e78638;
  /* --teme-color:#4c549c; */
}
.form_sec.ongoing_sec a.nav-link.active,
.form_sec.ongoing_sec a.nav-link p {
  color: var(--textblue) !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.form_sec.ongoing_sec .nav-link.active {
  background-image: linear-gradient(45deg, transparent, transparent) !important;
  background: transparent !important;
  border-bottom: 4px solid #4e9d66 !important;
  font-weight: 600 !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  border-bottom: 4px solid #f6f7ff !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  background: rgb(246, 247, 255) !important;
}
.form_sec.ongoing_sec .nav-link.active p {
  font-weight: 600 !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  background-image: linear-gradient(45deg, transparent, transparent) !important;
}
.cardimg img {
  height: 150px;
  width: 100%;
}
.projects .card-body {
  padding: 0;
}
.rate {
  background: #f2f3f9;
  border-radius: 20px;
}
.rate .desc_grey_txt {
  color: var(--textblue) !important;
}
.text-gray {
  color: gray;
}
.font_13 {
  font-size: 13px !important;
}
.green_txt_big {
  color: #1e24b9;
  font-size: 15px !important;
}
.light_theme .green_txt_big {
  color: #1e24b9;
  font-weight: 600;
}

.orangetext {
  color: var(--themeorange) !important;
}
.text-blue {
  color: var(--textblue) !important;
}
.cardhead {
  font-weight: 600;
  font-family: "Orbitron", sans-serif;
  font-size: 18px !important;
}
.green_txt {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
}
.rate .desc_grey_txt {
  color: var(--textblue) !important;
  font-weight: 700;
}
.profimg {
  position: relative;
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  background: #120f50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profimg img {
  width: 90px !important;
  height: 90px !important;
  min-width: 90px !important;
  min-height: 90px !important;
  border-radius: 50%;
}

.light_theme .profimg {
  background: #fff;
  border: 1px solid #1e24b9db;
  box-shadow: 0 3px 10px rgb(0 0 0 / 64%);
}
.profimg .tokenimg {
  position: absolute;
  min-width: 35px !important;
  min-height: 35px !important;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: 0;
}
.infoimg img {
  width: 100%;
  border-radius: 10px;
}
.get-started-btn-fill.greenbtn {
  background: #519c67 !important;
  border-color: #519c67 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.greenbtn:hover {
  color: #fff !important;
  border-color: #519c67 !important;
}

.get-started-btn-fill.purplebtn {
  background: #16f9b2 !important;
  border-color: #16f9b2 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.purplebtn:hover {
  color: #fff !important;
  border-color: #16f9b2 !important;
}
.lightblue {
  background: #f6f7ff !important;
}
.input-group input.form-control::placeholder {
  color: #fff !important;
}
.input-group input::placeholder {
  font-size: 12px !important;
}
.ongoing_sec.proj_bg .card {
  height: 100%;
  border-radius: 0px !important;
}
/* .proj_bg{
  background: #f6f7ff;
} */
.border-bot div#header {
  border-bottom: 1px solid #d4d1d1;
}
.ongoing_sec.form_sec .img_card_outer {
  background: white;
}

@media only screen and (max-width: 575px) {
  .btn_black {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .bg_legend {
    width: 80px !important;
    min-width: 80px !important;
  }
  .legend_lable_text {
    font-size: 14px !important;
  }
  .badge_grad_new {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .carousel_banner {
    min-height: 300px !important;
    max-height: 300px !important;
  }

  .carousel_banner img {
    min-height: 300px !important;
    max-height: 300px !important;
  }
  .font_30 {
    font-size: 20px !important;
  }
  .mob_btn_grp_new button {
    min-width: 35px !important;
  }

  header .get-started-btn.mobil_wal {
    width: unset !important;
    border-radius: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .tetx_left_a_new,
  .coy_ff_link_btrnj {
    text-align: left !important;
    padding-left: 0px !important;
  }
  .flex_badges_dv.flex_badges_dv_new {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
  }
  .inside_soc_f {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .airtitle {
    font-size: 12px;
  }
  .middile_dark,
  .middile_light {
    display: none !important;
  }
  .flex_row_cen {
    display: block !important;
  }
  .block_link_new_blue.pl-1 {
    display: block !important;
    padding-left: 0px !important;
  }
  .btn_lock_info_div .get-started-btn {
    display: block !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }
  .btn4_div {
    display: block !important;
    text-align: center;
  }

  .btn4_div .get-started-btn,
  .btn4_div .get-started-btn-border {
    min-width: 175px !important;
    /* width: 100%; */
  }
  .dark_theme .row_add_remove .input-groups .input-group input {
    font-size: 10px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .dark_theme .row_add_remove .input-groups .input-group input::placeholder {
    font-size: 10px !important;
  }
  /* .chart_donut_res,.chart_donut_res .apexcharts-canvas
  {
    max-width: 150px !important;
    width: 150px !important;
  } */
  .input-groups .input-group.input_grp_profile_url .input-group-prepend,
  .input-groups .input-group.input_grp_profile_url input {
    /* width: 100% !important; */
  }
  /* .modal-content .icon_align_border
  {
    margin-left: auto;
    margin-right: auto;
  } */
  .btn_sec_mob_res .get-started-btn {
    font-size: 10px !important;
  }
  .h3_res {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .flex_cont_end_flex {
    justify-content: flex-end;
  }
  .dblock_mob.d-flex {
    display: block !important;
    width: 100% !important;
  }
  .searc_style_1 {
    max-width: 100% !important;
  }
  .mt_18_px {
    margin-top: 0px !important;
  }
  /* .dblock_mob.d-flex>div
  {
  margin-bottom: 10px !important;
  } */
  .modal-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .logo_img_sm {
    max-width: 155px !important;
    width: 128px !important;
  }
  .whole_sec .prof-name {
    font-size: 25px !important;
  }
  .font_20 {
    font-size: 14px !important;
  }
  .coimg_soon_bg {
    background-size: cover, 100% !important;
    background-position: top, center;
  }
  .coimg_soon_center img {
    max-width: 150px !important;
  }
  .logo_img_sm {
    max-width: 155px;
  }
  .whole_sec {
    /* padding-top: 200px !important; */
  }
  .nav_parnt {
    flex-direction: column;
  }
  .nav_parnt_2,
  .nav_parnt_1 {
    /* display: flex;
    align-items: center;
    margin-top: 20px; */
  }
  .btn_row {
    flex-wrap: wrap !important;
  }
  .btn_row button {
    width: calc(50% - 1rem) !important;
  }
  .tab_div .nav-pills .nav-link {
    /* margin-right: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 14px !important; */
    margin-right: 1px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;
  }
  .header_flex {
    flex-direction: column;
  }
  .nav_parnt {
    /* margin-top: 20px !important; */
  }
  .inner_card {
    background-size: cover !important;
  }

  .grid_view {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple-new {
    margin-top: 20px;
  }
}

.banner_title {
  font-size: 48px !important;
  font-weight: 600;
  line-height: 1.1;
  color: #1e24b9;
  margin-bottom: 24px;
}
.hr_yellow {
  border-top: 1px solid rgb(231, 227, 235) !important;
  width: 100% !important;
}
.banner_subtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.single_sale_card p.text-white span,
.single_sale_card p.white_txt_sm span {
  font-size: 17px;
}
p.word_brk_addrs.text-white {
  font-size: 17px !important;
  color: #6a6a6a !important;
}

.flex_cen_col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav_parnt_2 {
    display: flex;
    align-items: center;
  }
  .btn-purple {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .grid_view {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple {
    margin-top: 20px;
  }
  .grid_view .btn-purple-new {
    margin-top: 20px;
  }
}
.footer {
  margin-top: -50px;
}
/* inner css */

.arro_change .fa-angle-down:before {
  content: "\f106";
}

/* Modal css */
.modal-dialog .modal-content {
  /* background: linear-gradient(111.68deg, rgb(21, 2, 54) 0%, rgb(21, 2, 54) 100%); */
  /* background: #0a071e 0% 0% no-repeat padding-box; */
  -webkit-clip-path: polygon(
    0 30px,
    30px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    0 30px,
    30px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
  background: #1e24b9;
  /* box-shadow: 0px 0px 6px #716f6d; */
  border-radius: 0px !important;
  /* box-shadow: 0px 1px 9px -2px #1E24B9 !important; */
}
.modal-dialog .modal-header {
  /* background: linear-gradient(111.68deg, rgb(21, 2, 54) 0%, rgb(21, 2, 54) 100%); */
  /* background: #0a071e 0% 0% no-repeat padding-box;
border:none !important; */
  padding-bottom: 2rem !important;
  padding-top: 4rem !important;
  /* border-top-left-radius: 30px !important;
border-top-right-radius: 30px !important; */
}
.sec-head {
  font-size: 20px !important;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-family: "Orbitron", sans-serif;

  margin-bottom: 0px !important;
  margin-top: 7px;
}
.walletmname {
  font-size: 20px !important;
}
.modal-dialog .modal-header .close {
  position: absolute;
  background-color: transparent;
  width: 25px;
  height: 25px;
  color: #fff !important;
  font-size: 12px;
  opacity: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 25px;
  top: 25px;
}
.light_theme .modal-dialog .modal-header .close {
  color: black !important;
}
.text-highlight {
  color: #1e24b9 !important;
  font-weight: 600 !important;
}
.wallet-lists ul li {
  list-style-type: none !important;
  transition: 0.5s;
}
.wallet-lists ul li:hover {
  /* background-color: #13003a !important; */
  background-color: #121216 !important;
  transition: 0.5s;
}
.wallet-lists li img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.side-head-li {
  font-size: 12px !important;
}
.wallet-lists ul {
  padding-left: 0px !important;
}

.wallet-lists li {
  grid-gap: 10px;
  gap: 10px;
  padding: 16px;
  display: flex;
  align-items: baseline;
  margin: 0px 0 0;
  border-radius: 0px;
  cursor: pointer;
}
.side-head-li {
  color: #1e24b9;
}
.wal-option {
  text-align: left;
}
.modal-header p {
  font-size: 14px !important;
}

.input-group input {
  /* background: #13003a; */
  background: #04030e 0% 0% no-repeat padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  border: 1px solid #04030e !important;
  color: #fff !important;
}

.input-group-text.doll {
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;

  border-radius: 0px;
  color: #fff !important;
}

.btn-purple-new {
  color: #fff !important;
}
.font_14 {
  font-size: 14px !important;
}

.input-groups input,
.input-groups .input-group-text {
  font-size: 14px !important;
}

.bord-top-none {
  border: none !important;
}
.doll-btn {
  background-color: transparent !important;
  border: none !important;
}

.input-group input,
.light_theme .input-group input,
.light_theme .input-group input.form-control {
  min-height: 42px !important;
  font-size: 12px !important;
  /* color:#d6d6d6 !important; */
}
.input-group input::placeholder,
.input-group > .form-control::placeholder {
  font-size: 12px !important;
}
.div_brn_grid {
  display: grid !important;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}

@media only screen and (max-width: 767px) {
  #header1 .notchedButton {
    min-width: 145px !important;
  }
  .row_invested_sec {
    gap: 20px;
  }
  .flex_col_res_mob {
    flex-direction: column;
    gap: 20px;
  }
  .footer {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .text-right-res-new {
    text-align: right;
  }
  .flex_cont_end_flex {
    justify-content: flex-end !important;
  }
  .nav_parnt_2 {
    display: flex;
    align-items: center;
  }
  .container.container_custom {
    max-width: 1170px !important;
  }
}

/* new css */
.grid_img_div {
  display: grid;
  grid-template-columns: 90px calc(97% - 90px);
  gap: 3%;
  align-items: center;
}
.card_style_1 {
  background-color: transparent !important;
  border: none !important;
  /* border:2px solid #90c6f8 !important; */
  border-radius: 24px !important;
  /* box-shadow: 1px -2px 17px 3px #302b2b36; */
}
table,
.whitebg {
  background: white !important;
}
.form_sec .card_style_1 {
  /* border:2px solid #90c6f8 !important; */
}
.blur {
  filter: blur(4px);
}
.proj_bg .card-body {
  background: white;
}

.btn_grp_yel a {
  background-image: linear-gradient(
    to bottom,
    #16f9b2 6%,
    #16f9b2 59%,
    #16f9b2
  );
  border: 1px solid #16f9b2 !important;
  background-color: #16f9b2 !important;
  color: #fff;
  border: none;
  border-radius: 0px !important;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 !important;
}
.btn_grp_yel a:hover {
  background-color: transparent !important;
  background: transparent !important;
  color: var(--textblue) !important;
  border-color: var(--textblue) !important;
}
.badge-green {
  padding: 4px 10px !important;
  border-radius: 50px !important;
  background-color: transparent;
  border: 2px solid #508832;
  color: #508832;
  position: relative;
}
.badge-blue {
  background-color: var(--textblue);
  border: 1px solid var(--textblue) !important;
  color: var(--textblue) !important;
  border-radius: 25px !important;
  position: relative;
  padding: 11px 17px !important;
}
.badge-blue.infobtn span {
  color: #fff !important;
}
.badge-blue {
  padding: 9px 10px !important;
}
.badge-blue.infobtn span.green_dot {
  background: #fff !important;
}
.green_dot {
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 10px;
  left: 9px;
}
.green_txt {
  padding-left: 12px;
}
.badge-yellow-fill {
  background-color: #e78638;
  border: 1px solid #e78638;
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 500 !important;
  padding: 5px 10px !important;
}
.grid_img_div img {
  max-width: 90px;
  max-height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  /* border: 2px solid #35b6ff; */
  padding: 5px;
}
.yellow_txt {
  color: #fff000;
  font-weight: 700;
}
.yellow_txt_sm {
  color: #fff;
}
.font_20 {
  font-size: 20px;
}
.font_16 {
  font-size: 16px;
}
.font_18 {
  font-size: 18px;
}
.yellow_bar .progress-bar {
  background-color: #25f104;
  background: #25f104;
  /* background-image: linear-gradient(-200deg,#1E24B9b2 6%,#1E24B9 59%,#1E24B9); */
  border-radius: 20px !important;
}

.grey_bar .progress-bar {
  background-color: #666666;
  background-image: linear-gradient(-200deg, #666666 6%, #666666 59%, #666666);
  border-radius: 20px !important;
}

.red_bar .progress-bar {
  background-color: #e40404;
  background-image: linear-gradient(-200deg, #e40404 6%, #e40404 59%, #e40404);
  border-radius: 20px !important;
}
.light_theme .yellow_bar .progress-bar {
  background-color: #1e24b9;
  background: #1e24b9;

  /* background: linear-gradient(275deg, #6300DD 0%, rgb(116 5 255 / 77%) 100%) !important; */
  /* background: #1E24B9  !important;   */
  border-radius: 20px !important;
}

.light_theme .grey_bar .progress-bar {
  background-color: #666666;
  background-image: linear-gradient(-200deg, #666666 6%, #666666 59%, #666666);
  border-radius: 20px !important;
}

.light_theme .red_bar .progress-bar {
  background-color: #e40404;
  background-image: linear-gradient(-200deg, #e40404 6%, #e40404 59%, #e40404);
  border-radius: 20px !important;
}
.yellow_bar {
  /* background-color: #fff !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  height: 10px !important;
  font-size: 6px !important;
}

.grey_bar {
  /* background-color: #fff !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  height: 10px !important;
  font-size: 6px !important;
}
.red_bar {
  /* background-color: #fff !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  height: 10px !important;
  font-size: 6px !important;
}
.white_txt_sm {
  color: #fff;
}

@media only screen and (min-width: 992px) {
  .no_data_text {
    min-height: calc(100vh - 300px);
  }
  .whole_sec {
    padding-top: 100px;
  }
  /* .col-lg-6.col-lg-6-custom
  {
    flex: 0 0 47.5% !important;
    max-width: 47.5% !important;

  } */
  .offset-lg-1-custom {
    margin-left: 5% !important;
  }
}

.round_img {
  max-width: 90px;
  max-height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  border: 2px solid #35b6ff;
  padding: 5px;
}
.font_35 {
  font-size: 35px !important;
}
.badge-green-big {
  padding: 7px 12px 8px !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}
.badge-green-big,
.badge-green-big .green_txt {
  text-transform: uppercase !important;
  font-size: 12px !important;
}
.badge-green-big .green_txt {
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.badge-green-big .green_dot {
  top: 8px !important;
}
.badge-yellow-fill-big {
  padding: 7px 16px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}

.badge-green-fill {
  background-color: #31874a;
  border: 1px solid #31874a;
  color: #fff;
  border-radius: 25px !important;
  position: relative;
  padding: 8px 20px !important;
  text-transform: uppercase;
}
.line_he_big {
  line-height: 25px;
}

.get-started-btn-fill {
  background-color: var(--textblue) !important;
  border-radius: 40px !important;
  border: 1px solid var(--textblue) !important;
  color: #fff;
  padding: 4px 25px;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: uppercase !important;
  letter-spacing: 1px;
}
.cur_pointer .get-started-btn-fill {
  pointer-events: none;
}
.get-started-btn-fill:hover {
  /* background:transparent !important; */
  border: 1px solid var(--textblue) !important;
  /* color:var(--textblue); */
  opacity: 0.65;
  color: #fff !important;
}
.tab_div .nav-pills {
  border-bottom: 1px solid #44bdff;
}

.tab_div .nav-pills .nav-link.active,
.tab_div .nav-pills .show > .nav-link,
.tab_div .nav-pills .nav-link:hover {
  background-image: linear-gradient(
    to bottom,
    var(--themeorange) 6%,
    var(--themeorange) 59%,
    var(--themeorange)
  );
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 10px 10px 0px 0px !important;
}
.tab_div .nav-pills .nav-link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin-right: 15px;
}

.table_style_1 th,
.table_style_1 td {
  color: #1e24b9;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  align-items: center;
  vertical-align: middle !important;
}
.table_style_1 th {
  color: #fff !important;
}
.table_style_1 td:last-child {
  text-align: right;
}
.table_style_1 th {
  background-color: #212121 !important;
}
.table_style_2 th:last-child,
.table_style_2 td:last-child {
  max-width: 120px !important;
  width: 120px !important;
}
.table_style_2 th:nth-child(3),
.table_style_2 td:nth-child(3) {
  min-width: 200px !important;
  max-width: 200px !important;
}
.searc_style_1 {
  background-color: rgb(242, 243, 249) !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  max-width: 235px;
  padding: 0px 16px !important;
  margin-left: auto;
  border: 1px solid #d7caec !important;
  border-radius: 16px !important;
  color: var(--textblue) !important;
  min-height: 40px;
  height: 40px;
  color: #1e24b9 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.light_theme .dd_meu_header.dd_coin_landing {
  background: rgb(242, 243, 249) !important;
  color: #1e24b9 !important;
  max-width: 168px !important;
  min-width: 168px !important;
  border: 1px solid rgb(215, 202, 236) !important;
  transform: translate(0px, 38px) !important;
  border-radius: 0px 0px 16px 16px !important;
}
.light_theme .dropdown_yelo.dropdown_purple.dropdown.show {
  border-radius: 16px 16px 0px 0px !important;
}

.dd_meu_header.dd_coin_landing .dropdown-item {
  max-width: 168px !important;
  min-width: 168px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 16px !important;
}
.dd_meu_header.dd_coin_landing .dropdown-item:hover {
  background: rgb(215, 202, 236) !important;
}
.dropdown_purple button {
  background-color: rgb(242, 243, 249) !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  max-width: 235px;
  border: 1px solid #d7caec !important;
  border-radius: 16px !important;
  min-height: 40px;
  height: 40px;
  color: #1e24b9 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.dropdown_purple.dropdown_yelo {
  background: rgb(242, 243, 249) !important;
  border: 1px solid #d7caec !important;
  color: #1e24b9 !important;
  height: 40px !important;
  padding: 0px 10px !important;
  border-radius: 16px !important;
  min-width: 168px !important;
}
.dropdown_purple.dropdown_yelo button {
  padding: 0px 8px !important;
  width: 100% !important;
  color: #1e24b9 !important;
  justify-content: space-between !important;
}
/* .searc_style_1::placeholder
{
  color: #1E24B9 !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;

} */
.top_heqad_sec {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .flex_end_input .d-flex {
    justify-content: flex-end;
  }
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 0px !important;
  }
  .top_heqad_sec .searc_style_1 {
    position: absolute;
    right: 0px;
    top: 50px;
    z-index: 2;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .top_heqad_sec .searc_style_1 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.coin_ul_desc {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;
}

.coin_ul_desc li {
  list-style-type: none;
  display: inline-block;
  margin: 0px 20px 10px;
  text-align: left;
}
.coin_desc_li_one {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: gray;
}
.coin_ul_desc li .coin_name_title {
  padding-left: 0px;
  font-weight: 600;
  font-size: 20px;
}

@media (min-width: 576px) {
  .modal-dialog.modal-dialog-lg {
    max-width: 660px;
    margin: 1.75rem auto;
  }
}

.card_toek {
  background-color: #1b1b1b !important;
  border-radius: 10px !important;
  border: none !important;
}

.coin_name_title {
  color: var(--textblue) !important;
  font-weight: 700;
  font-size: 14px !important;
}

.input-groups .input-group {
  /* border: 1px solid #04030e; */
  border-radius: 0px !important;
  /* background-color: #04030e; */
  /* box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset; */
  color: #fff;
  font-size: 14px !important;
}

.input-groups .input-group input.form-control::placeholder {
  color: #d6d6d6 !important;
  font-size: 12px !important;
}
.input-groups .input-group .input-group-text,
.input-groups .input-group .input-group-text.doll {
  color: #1e24b9 !important;
  font-size: 16px !important;
}
.login_text .input-groups .input-group .input-group-text {
  color: #1e24b9 !important;
}
.lightblue .yellow_txt {
  color: var(--textblue) !important;
}
.input-groups .input-group input::placeholder {
  color: #fff !important;
}

.get-started-btn-fill.text-capitalize {
  text-transform: capitalize !important;
}

.btn_row {
  justify-content: space-between;
  display: flex;
}

.input-group input:focus {
  /* background-color: #fff000ba !important; */
  background-color: transparent !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.modal-open .modal {
  padding-right: 0px !important;
}

.icon_black {
  color: #fff;
  cursor: pointer;
}

.icon_black.active {
  color: var(--textblue);
}

.dropdown_yelo {
  background-image: linear-gradient(
    to bottom,
    var(--textblue) 6%,
    var(--textblue) 59%,
    var(--textblue)
  );
  border: 1px solid var(--textblue) !important;
  color: #fff;
  border-radius: 40px !important;
  padding: 4px 25px;
  font-size: 14px !important;
  font-weight: 700;
}

.dropdown_yelo .btn {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  box-shadow: 0 0 0 0rem rgb(72 180 97 / 50%) !important;
  display: flex;
  align-items: center;

  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.dropdown_yelo img {
  max-width: 20px;
  margin-right: 5px;
}

.dd_meu_header {
  background-color: #262626 !important;
  border: 1px solid var(--textblue) !important;
  color: var(--textblue) !important;
  transform: translate(-15px, 50px) !important;
  border-radius: 20px !important;
  overflow: hidden;
}
.dd_meu_header a {
  color: var(--textblue) !important;
  cursor: pointer;
  /* border-radius: 20px !important; */
}

.dd_meu_header a:hover {
  background-color: #313131;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .legend_lable_text {
    font-size: 16px !important;
  }
  .flex_badges_dv.flex_badges_dv_new {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
  }
  .inside_soc_f {
    display: flex !important;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
  .flex_row_cen .banner_subtitle {
    font-size: 18px !important;
  }
  /* .btn_lock_info_div .get-started-btn
  {
    padding: 0px 8px !important;
    font-size: 10px !important;
  } */
  /* .btn4_div .get-started-btn
  {
    padding: 0px 8px !important;
    font-size: 11px !important;
  } */
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .coimg_soon_bg {
    background-size: cover, 75% !important;
  }
  .detail-list .get-started-btn,
  .detail-list .get-started-btn-fill {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .whole_sec {
    padding-top: 140px !important;
  }
  .nav_parnt.d-flex {
    display: block !important;
  }
  .nav_parnt_1,
  .nav_parnt_2 {
    display: inline-flex !important;
  }

  .header_flex {
    flex-direction: column;
  }

  .nav_parnt {
    flex-direction: column;
    /* margin-top: 20px; */
  }
  .nav_parnt_2,
  .nav_parnt_1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

/* LOADER CSS */
.logo_overlay {
  min-height: 100vh;
  position: absolute;
  opacity: 0.05;
  width: 100%;
  top: 0px;
  /* background-color: #fff !important;
  background-image: url(../images/dark-bc.png) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}
.de.logo_overlay {
  opacity: 1;
}
.dark_theme .logo_overlay {
  background-color: #fff000 !important;
  /* background-image: url(../images/bg.png);
  background-size: cover; */
  overflow: hidden;
}
.dark_theme body {
  background-image: url(../images/dark-bc.png) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
}

#loader_div {
  position: absolute;
  min-height: 100vh;
  left: 50%;
}

.logo_ovelay {
  opacity: 0.5;
  min-height: 100vh;
  position: absolute;
  top: 0px;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo_load {
  max-width: 80px;
  position: absolute;
  top: calc(40% + 75px);
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
}
@keyframes loader-02 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin_round {
  animation-name: spin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 0.2em solid transparent;
  border-left-color: #1e24b9;
  border-right-color: #1e24b9;
  border-radius: 50%;
  animation: 1s loader-02 linear infinite;
  position: absolute;
  top: 40%;
  left: calc(50% - 75px);
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

body.overflow_hidden {
  overflow: hidden;
}
/* END LOADER CSS */
/* LIGHT THEME CSS */
.light_theme,
.light_theme #root {
  /* background-color: #fff !important; */
}
.light_theme .dd_meu_header {
  background-color: #fff !important;
}
.light_theme .dd_meu_header a:hover {
  background-color: #f4f4f4;
}
.light_theme .text-white {
  color: black !important;
  font-weight: 600;
}
.light_theme .icon_black,
.light_theme .side-head-li,
.light_theme .white_txt_sm,
.light_theme .tab_div .nav-pills .nav-link,
.light_theme .input-group input,
.light_theme .input-group input::placeholder,
.light_theme .input-group-text.doll {
  color: #313131 !important;
}
.light_theme .coin_name_title {
  color: white;
}
.light_theme .sec-head {
  color: #fff !important;
}
.wallet-lists li:hover h3 {
  color: white !important;
  font-size: 12px;
}
.light_theme .icon_black.active {
  color: var(--textblue) !important;
}
.light_theme .modal-dialog .modal-content {
  background: #fff;
}

.light_theme .modal-dialog .modal-header {
  background-color: #fff !important;
  color: white;
}
.light_theme .table_style_1 th {
  background-color: transparent !important;

  color: #1e24b9 !important;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 12px;
}
.light_theme .card_toek {
  background-color: #f2f3f9 !important;
  color: gray;
  border-radius: 20px !important;
}
.light_theme .wallet-lists ul li:hover {
  background-color: transparent !important;
}
.light_theme .wallet-lists ul li:hover h3 {
  color: #368673 !important;
}
.light_theme .wallet-lists ul li:hover .img {
  transform: scale(1.1);
  transition: 0.2s;
}
.light_theme .modal-dialog .modal-header {
  -webkit-box-align: center;
  align-items: center;
  /* background: linear-gradient(111.68deg, #efe0ff 0%, #efe0ff 100%); */
  background: #fff;
  display: flex;
}
.wallet-lists ul li {
  display: inline-block;
}
.wallet-lists ul {
  /* border-bottom: 1px solid #1E24B947; */
  padding: 23px 0 23px;
}
.wallet-lists ul li .img {
  margin-bottom: 11px;
}
button.themebtn {
  background: var(--textblue);
  color: white;
  border-radius: 30px;
  padding: 8px 16px;
  border: none;
  /* border: 1px solid #313982; */
}
button.themebtn:hover {
  opacity: 0.65;
}
.wallet-lists ul {
  display: flex;
  justify-content: space-around;
}
.light_theme .modal-dialog .modal-header h3 {
  font-weight: 600;
  color: #fff !important;
  line-height: 1.1;
}
button.close span {
  font-size: 16px;
  font-weight: 300;
}

.light_theme .tab_div .nav-pills {
  border-color: #ccc !important;
}
.light_theme .table_style_1 th,
.light_theme .table_style_1 td {
  border-color: #e7e3eb !important;
}

.light_theme .logo_overlay {
  background-color: #f7f7f7 !important;
  background-image: linear-gradient(45deg, #f7f7f7, #f7f7f7) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
}
.light_theme .title_span {
  color: black !important;
  font-weight: 500;
}
/* END LIGHT THEME CSS */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flex_badges_dv.flex_badges_dv_new {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
  }
  .inside_soc_f {
    display: flex !important;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
  .ing_char_robot {
    object-fit: contain !important;
  }
  /* .btn_lock_info_div .get-started-btn
  {
    padding: 0px 8px !important;
    font-size: 12px !important;
  } */
  /* .btn4_div .get-started-btn
  {
    padding: 0px 8px !important;
    font-size: 12px !important;
  } */
  .profimg {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
  }

  .profimg img {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    border-radius: 50%;
  }
  .row_left_res .text-right {
    text-align: left !important;
  }
  /* .card_bg_wizard .d-flex
  {
flex-direction: column;
justify-content: center;
  }
  .card_bg_wizard
  {
    height: unset !important;
  }
  .card_bg_wizard .wizard_badge_num
  {
margin-bottom: 10px;
  } */
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .dd_content_lang {
    transform: translate(-50%, -5%) !important;
  }
  .locing_days_h {
    min-height: 42px;
  }
  .coimg_soon_bg {
    background-size: cover, 65% !important;
  }
  .whole_sec {
    padding-top: 140px !important;
  }
  .header_flex {
    flex-direction: row;
  }
  /* .nav_parnt.d-flex
  {
    display: block !important;
    margin-top: 20px;
  } */
  .nav_parnt_1,
  .nav_parnt_2 {
    display: inline-flex !important;
  }
}

@media only screen and (min-width: 992px) {
  .nav_parnt_1 {
    display: inline-flex !important;
    /* margin-right: 1rem !important; */
  }
}
#header {
  position: fixed;
  width: 100% !important;
  background: transparent;
  z-index: 3;
}
#header.header-scrolled {
  position: fixed;
  width: 100% !important;
  background: #03060a;
  background-image: url(../images/bg.png);
  background-size: cover;
  z-index: 99;
}
/* #header.fixed-top-navbar
{
  background: #0a071e;

} */

.light_theme #header {
  /* background-color: #f5eeff  !important;
  box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  background: #ffff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 3px 6px #6EB533 !important; */
  /* border-bottom: 1px solid gray; */
}

.light_theme #header.header-scrolled {
  /* background-color: #f5eeff   !important; */
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #6eb533;
}

/* HEADER NEW CSS */
/* mobile navbar */
.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}
.mobile-nav {
  position: fixed;
  top: 61px;
  right: 0px;
  left: 15px;
  z-index: 1000;
  overflow-y: auto;
  transition: ease-in-out 0.2s;
  /* border-radius: 10px; */
  padding: 10px 0;
  opacity: 0;
  /* display: none; */
}

.light_theme .mobile-nav {
  background: #f4f4f4;
}
.mobile-nav {
  background: #242424;
  padding-top: 30px !important;
}

.mobile-nav-toggle i.clicked {
  color: #fff;
  font-size: 20px;
}
.mobile-social li a span {
  background: #e6e6e8;
}
button#dropdown-basic-1 {
  color: #264663;
}
.mobile-nav.active {
  opacity: 1;
  display: block;
}
.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-nav a {
  display: block;
  position: relative;

  outline: none;
}
.mobile-nav-toggle {
  /* position: fixed;
  top: 15px;
  right: 15px; */
  z-index: 1000;
  border: 0;
  background: none;
  font-size: 20px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}
.icofont-close:before {
  content: "\eee4";
}
.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}
#mobileLayer.active {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: transparent;
  overflow: hidden;
  transition: ease-in-out 0.2s;
}
@media (max-width: 991px) {
  #sidebar {
    z-index: 9999 !important;
  }

  .dropdown-content {
    top: 40px !important;
  }
  .dropdown-content a {
    text-align: left;
  }
  div#header {
    padding: 0;
  }

  .whole_sec {
    padding-top: 100px !important;
    /* padding-bottom: 120px !important; */
    padding-bottom: 70px !important;
  }
  .nav_parnt.d-flex,
  .nav_parnt_1,
  .nav_parnt_2 {
    /* display: block !important; */
  }
  .nav_parnt_1 .dropdown_yelo {
    /* display: block !important; */
    margin-right: 0px !important;
    padding: 7px 0px;
    /* max-width: 200px !important; */
    /* min-width: 200px !important; */

    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mobile-nav-toggle i {
    color: var(--textblue);
    /* margin-top: 8px; */
  }
  .mobile-nav-toggle i.clicked {
    color: var(--textblue);
    /* margin-top: 8px; */
  }
  header .get-started-btn {
    /* max-width: 200px;
    min-width: 200px; */
    /* padding: 8px 8px !important; */
    /* margin-top: 20px; */
  }
  .logo_img_sm {
    max-width: 100px !important;
  }
}
#burger.clicked::before {
  content: "\2190" !important;
}

@media (max-width: 991px) {
  .row_frist_home .col-12:last-child {
    margin-bottom: 3rem !important;
  }
  .headerleft .dropdown.hoverbtn {
    display: none;
  }

  .dd_meu_header {
    min-width: 200px !important;
    left: 15px !important;
  }
  .theme-btn {
    margin-top: 20px !important;
  }
  .nav_parnt {
    text-align: center;
  }
  .left_stye {
    opacity: 1;
    left: 0;
    transition: 1s;
    min-height: 100vh;
    overflow-y: auto;
  }
}

header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.modal-backdrop,
.modal {
  z-index: 9999999 !important;
}

#mobile_nav_item {
  display: none !important;
}
#mobile_nav_item.left_stye {
  display: block !important;
}
/* END HEADER CSS */

/* ADMIN CSS */
.whole_sec_height {
  min-height: calc(100vh);
}

.input-groups .get-started-btn-fill {
  border-radius: 16px !important;
}

.inputs_switdch .input-group {
  justify-content: space-between;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  top: 0px;
  left: 6px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider.round {
  border-radius: 20px;
}
.slider,
.slider:before {
  position: absolute;
  transition: 0.4s;
}
.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
}
.slider.round:before {
  border-radius: 50%;
}
.slider:before {
  content: "";
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: left 200ms ease-in 0s;
  width: 26px;
  z-index: 1;
  /* height: 16px;
  width: 16px;
  left: 3px;
  bottom: 4.5px;
  background-color: var(--textblue); */
}
input:checked + .slider {
  background-image: linear-gradient(60deg, var(--textblue), var(--textblue));
}
input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #fff;
}

.detail-list label {
  color: #fff;
  margin-bottom: 15px;
  margin-top: 15px;
}

.inputs_switdch label {
  margin-bottom: unset;
  margin-top: unset;
}

.detail-list .get-started-btn-fill {
  text-transform: capitalize !important;
}

.light_theme .detail-list label {
  color: #313131 !important;
}
.light_theme .slider {
  /* background-color: #dfdcdc; */
  background-color: rgb(242, 243, 249);
  border-radius: 24px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  height: 32px !important;
  width: 56px !important;
  transition: background-color 200ms ease 0s;
}
.input-groups.inputs_switdch .input-group {
  border: none !important;
  background: transparent !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 0px 0px 0px inset !important;
}
.light_theme input:checked + .slider:before {
  background-color: #fff;
}

/* END ADMIN CSS */

/* COMING SOON CSS */
.coimg_soon_bg {
  /* background-image: url(../images/coming_bg.png),url(../images/coimg_img_bg.png);
  background-size: cover,40%;
  background-position: top,center;
  background-repeat: no-repeat,no-repeat; */
  min-height: 100vh;
}

.coimg_soon_center img {
  max-width: 200px;
}
.coimg_soon_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  padding-top: 50px;
  padding-bottom: 50px;
}

.coimg_soon_text {
  font-weight: 700;
  font-size: 40px;
}
.coimg_soon_text_white {
  color: #fff;
}
.coimg_soon_text_yellow {
  color: var(--textblue);
}
.light_theme .coimg_soon_text_white {
  color: #313131;
}
.coing_soon_img {
  position: relative;
  /* min-width: 80%; */
  /* min-height: 400px; */
}
.coing_soon_img {
  /* content: ""; */
  background-image: url(../images/coimg_img_bg.png);
  background-size: cover;
  background-position: top;
  /* position: absolute;
  top: -135px;
    width: 400px;
    height: 400px;
    left: -50%; */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flex_badges_dv.flex_badges_dv_new {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
  }
  .inside_soc_f {
    display: flex !important;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
  /* .btn_lock_info_div .get-started-btn
  {
    padding: 0px 8px !important;
    font-size: 11px !important;
  } */
  /* .btn4_div .get-started-btn
  {
    padding: 0px 6px !important;
    font-size: 11px !important;
  } */
  .row_left_res .text-right {
    text-align: left !important;
  }
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .coimg_soon_bg {
    background-size: cover, 55% !important;
  }
}

/* END COMING SOON CSS */

/* CRAETE CSS */
.img_card_outer {
  max-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 220px;
}
.img_card_outer img {
  object-fit: contain;
  max-height: 125px;
}

.card_img_craete .card-footer {
  background-color: var(--textblue) !important;
  background: linear-gradient(
    111.68deg,
    rgb(242, 236, 242) 0%,
    rgb(232, 242, 246) 100%
  );
  /* background-image: linear-gradient(to bottom,var(--textblue) 6%,var(--textblue) 59%,var(--textblue)); */
  color: #fff !important;
  border-radius: 0px 0px 18px 18px !important;
  font-weight: 700;
  font-size: 16px !important;
}

a {
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.font_25 {
  font-size: 25px !important;
}
.input_desc_sm {
  color: #fff;
}
.yelow_desc_blk {
  color: #56596b !important;
  font-size: 14px !important;
  padding-left: 10px;
  font-weight: 400;
}
.input_desc_sm,
.light_theme .input_desc_sm {
  color: #56596b !important;
  font-size: 14px !important;
}
.form_sec.ongoing_sec .nav.nav-pills {
  justify-content: center;
  border: navajowhite;
  background-color: #fcfdff;
  /* border-bottom: 2px solid #cbced7; */
}
.swithch_inline .inputs_switdch {
  max-width: 62px !important;
}
.toggle_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.datepicker_input input {
  width: 100% !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  /* width: calc(100% + 60px) !important;
  width: calc(100% - 116px) !important; */

  outline: #f3bd32 auto 0px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #f3bd32 !important;
  color: #fff000 !important;
}
.date_inoput_grps {
  display: flex;
}
/* .date_inoput_grps .input-group,.date_inoput_grps input,
.date_inoput_grps .react-datepicker__input-container
{
  width: calc(100% - 60px) !important;
} */
.date_inoput_grps .input-group {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.date_inoput_grps .input-group-append,
.date_inoput_grps .input-group-append button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.cur_pointer {
  cursor: pointer;
}

.note_desc p {
  color: #ffffff !important;
  font-size: 12px !important;
  margin-top: 10px;
}
.light_theme .note_desc p {
  color: black !important;
  font-weight: 500;
}

.text_are_grp textarea {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0px;
  background-image: url("../images/input_border.png");
  background-size: 100% 100%;
  color: #fff !important;
  resize: none !important;
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
  width: 100% !important;
  /* border: 1px solid #1E24B9 !important; */
}

.text_are_grp textarea:focus {
  background-color: transparent !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.swithch_inline .input-group {
  border: none !important;
}
.inputs_switdch {
  margin-right: 10px;
}
/* .light_theme .input_desc_sm,
.light_theme .text_are_grp textarea
{
  color:#fff !important;
} */

/* END CREATE CSS */

.logo_img_sm {
  max-width: 200px !important;
  background-image: url(../images/logo_grad.png);
  background-size: contain;
  background-position: left;
  width: 180px;
  height: 56px;
  background-repeat: no-repeat;
  display: block;
  position: relative;
}
.logo_img_sm::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100px;
  top: -30px;
  right: -5px;
  background: #9f9b9bad;
}

@media (max-width: 991px) {
  .logo_img_sm::before {
    display: none;
  }
}

.web_menu {
  padding-top: 5px;
  padding-bottom: 5px;
}
.dark_theme .logo_img_sm {
  background-image: url(../images/logoo.png);
}
body {
  /* background-image: url(../images/light-bc.png);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat; */
}
body.dark_theme {
  /* background-image: url(../images/dark-bc.png);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat; */
}
/* .....v...... */
.border-rad-15 table {
  border-radius: 24px;
}
.border-rad-15 {
  border-radius: 24px;
  border: 1px solid #d1d3d5;
}
.table-responsive.border-rad-15 td {
  padding: 10px 15px;
}
.upbtn {
  background: #e78638;
  border-color: #e78638 !important;
  color: white !important;
}
.mobile-nav a.dropbtn {
  color: #16f9b2;
}
.dropdown.headerlinkbtn.hoverbtn button {
  color: #16f9b2;
  background: transparent;
  border: none;
  height: 47px;
  line-height: 1;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.hoverbtn .dropdown-menu {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(231, 227, 235);
  border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.mobile-nav .headerlinkbtn {
  display: block !important;
  margin-right: 0px !important;
  padding: 7px 25px;
  max-width: 200px !important;
  min-width: 200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #16f9b2;
  border-radius: 28px;
  margin-top: 20px;
}
.mobile-nav .dropdown.hoverbtn:hover .dropdown-content {
  width: auto;
}
.mobile-nav .dropdown.hoverbtn:hover .dropbtn {
  background-color: #f6f7ff00;
}
.mobile-nav button.dropbtn {
  color: white !important;
  height: 0 !important;
  line-height: 1 !important;
  padding: 4px;
}
.headerright.dropdown.headerlinkbtn.hoverbtn button {
  height: auto;
  padding: 0;
}
.headerright.dropdown.hoverbtn:hover button {
  background: transparent;
}
.headerright .dropdown-content {
  width: auto !important;
  margin-top: 5px;
}
.headerright.dropdown.headerlinkbtn.hoverbtn button {
  color: rgb(255 255 255);
}
.headerleft {
  height: 80px;
  align-items: center;
}
.hoverbtn .dropdown-menu a {
  color: #1e24b9;
}
.dropdown.hoverbtn.active button.dropbtn {
  font-weight: 600;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04aa6d00;
  color: #16f9b2;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown.hoverbtn {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  /* margin-top: 4px; */
}

/* Links inside the dropdown */
.dropdown-content a {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  background: transparent;
  color: #1e24b9;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  font-size: 16px;
  height: 48px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  outline: 0px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f6f7ff;
  color: #1e24b9;
}

/* Show the dropdown menu on hover */
.dropdown.hoverbtn:hover .dropdown-content {
  display: block;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(231, 227, 235);
  border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 280px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown.hoverbtn:hover .dropbtn {
  background-color: #f6f7ff;
  border-radius: 18px;
}

.wallet-lists button {
  /* width: 100%; */
  /* padding: 14px; */
}
.hoverbtn:hover .dropdown-menu.dd_meu_header {
  display: block !important;
  opacity: 1 !important;
}
.dropdown.headerlinkbtn.hoverbtn button:focus + .dropdown-menu,
.dropdown.headerlinkbtn.hoverbtn button:hover + .dropdown-menu {
  display: block !important;
}
.countdown div span {
  display: inline-block;
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #f2ba30;
  padding: 0;
  position: relative;
}
.countdown {
  /* margin-left: 15px; */
  margin-right: 12px;
}
.countdown div > span {
  flex: 1;
}
.countdown div span:hover {
  color: #f2ba30;
}
.countdown div span span {
  border: none;
}
.countdown div {
  display: flex;
  gap: 4%;
  /* justify-content: center; */
  /* width: 70%; */
  /* margin: auto; */

  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
}
.countbtn.countinfo .countdown div {
  color: #2f800b;
}
.countbtn.countup .countdown div {
  color: #16f9b2;
}
.countdown div span span {
  display: block;
  font-size: 15px;
  background: #f2ba30;
  margin-bottom: 0;
  border-radius: 0;
  line-height: 1.3;
}

.datepicker_input input {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
  outline: 0px !important;
}

/* .salecount .badge.upbtn {
  padding: 14px 19px !important;
  font-size: 15px;
}
.salecount span.green_dot {
  margin-top: 12px !important;
} */
/* .infobtn span {
    color: #2f800b !important;
} */
/* .infobtn .green_dot {
  background: #2f800b;
} */
/* .infobtn  {
  border-color: #2f800b;
}

.dangerbtn span {
  color: #bb3a3a !important;
} */
.green_dot {
  background: #fff;
}
/* .dangerbtn  {
border-color: #bb3a3a;
} */
.dangerbtn {
  background: transparent;
  border-color: #ad0a1a;
  color: #ad0a1a;
}

/* .upbtn span {
  color: var(--textblue) !important;
} */
/* .upbtn .green_dot {
background: var(--textblue);
} */
/* .upbtn  {
border-color: var(--textblue);
} */

.countbtn span.green_txt {
  display: inline-block;
}
.countbtn span.green_dot {
  display: inline-block;
  margin-top: 2px;
}

.countbtn .badge {
  font-weight: 400;
}

button[disabled] {
  opacity: 0.4;
}
button[disabled]:hover {
  background: transparent;
  opacity: 0.4;
  color: #fff;
}

.browsebtn {
  position: relative;
  width: 100px;
}
.browsebtn input {
  width: 100px;
  cursor: pointer;
  height: 48px !important;
}
.browsebtn label {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  background: #e78638;
  width: 100px;
  border-radius: 16px;
  text-align: center;
  line-height: 3;
  color: #fff !important;
  pointer-events: none;
  height: 48px !important;
  font-weight: 700;
  border: 1px solid #ed8638;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
}
.light_theme .detail-list .browsebtn label {
  color: #fff !important;
}

.ribbon {
  content: "";
  height: 17px;
  background-image: linear-gradient(
    to bottom,
    #f55500 6%,
    #f57e00 59%,
    #f5a200
  );
  background-image: linear-gradient(
    to bottom,
    #f55500 6%,
    #f57e00 59%,
    #f5a200
  );
  position: absolute;
  left: -18px;
  top: 20px;
  width: 132px;
  top: 13px;
  z-index: 2;
  display: block;
  margin-left: auto;
  transform: rotate(329deg);
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.ribbox {
  overflow: hidden;
  position: relative;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  border-color: #04030e !important;
  opacity: 0.6 !important;
}

textarea[readonly],
.text_area_norla_colr.text_are_grp textarea[readonly] {
  background-color: transparent !important;
  border: 1px solid #04030e !important;
  border-color: #04030e !important;
  opacity: 0.6 !important;
  color: #fff !important;
}

.modalbtn .inputs input {
  border: 1px solid #d7caec;
  border-radius: 16px !important;
  background-color: rgb(242, 243, 249);
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  color: #1e24b9 !important;
  font-size: 16px !important;
}
.modalbtn .inputs input::placeholder,
.light_theme .input-group input.form-control::placeholder {
  /* color: #1E24B9 !important; */
  font-size: 12px !important;
}
.modalbtn .badge-yellow-fill-big {
  /* padding: 5px 15px 8px 15px !important; */
}
a {
  outline: none !important;
}
.get-started-btn.get-started-btn-fill,
.get-started-btn-fill {
  background-image: linear-gradient(
    to bottom,
    var(--textblue) 6%,
    var(--textblue) 59%,
    var(--textblue)
  );
  border: 1px solid var(--textblue) !important;
  color: #fff !important;
  cursor: pointer;
}
.get-started-btn.get-started-btn-fill:hover,
.get-started-btn-fill:hover {
  color: white !important;
  /* background: transparent !important; */
  /* color:var(--textblue) !important; */
}
.purple_head.yellow_txt_sm {
  color: #10103d !important;
  background: -webkit-linear-gradient(#10103d, #10103d, #10103d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
}
.desc_grey_txt {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 13px !important;
}
.light_theme .desc_grey_txt {
  color: black !important;
  font-weight: 500;
}
.text_blue_perc {
  /* color: #184370 !important; */
  font-weight: 600 !important;
}
.text_black_perc {
  color: #fff !important;
  font-weight: 600 !important;
}

.dark_theme .yellow_txt,
.dark_theme .yellow_txt_sm,
.dark_theme .text_black_perc,
.dark_theme .text_blue_perc {
  color: #fff !important;
}
.dark_theme .purple_head.yellow_txt_sm {
  color: #10103d !important;
  background: -webkit-linear-gradient(#0ba3ff, #2ab1ff, #56c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.tab_div .nav-pills .nav-link.active,
.tab_div .nav-pills .show > .nav-link,
.tab_div .nav-pills .nav-link:hover {
  color: #fff !important;
}

.dark_theme .table_style_1 th,
.dark_theme .table_style_1 td {
  color: #fff !important;
}
.cur_pointer {
  cursor: pointer !important;
}
.word_brk_addrs {
  word-break: break-all;
}
.a_blue_txt {
  color: var(--textblue) !important;
}
.a_blue_txt:hover {
  color: #fff !important;
}
.dark_theme .a_blue_txt:hover {
  color: #fff !important;
}

.light_theme .badge-yellow-fill-big.text-white,
.dark_theme .badge-yellow-fill-big.text-white {
  color: var(--textblue) !important;
  font-size: 14px !important;
}
.light_theme .badge-yellow-fill-big.text-white:hover,
.dark_theme .badge-yellow-fill-big.text-white:hover {
  color: #fff !important;
  font-size: 14px !important;
}

.light_theme .badge-yellow-fill-big.text-white.btn-danger,
.dark_theme .badge-yellow-fill-big.text-white.btn-danger {
  color: #fff !important;
}
.light_theme .badge-yellow-fill-big.text-white.btn-danger:hover,
.dark_theme .badge-yellow-fill-big.text-white.btn-danger:hover {
  background-color: transparent !important;
  color: #dc3545 !important;
}
.row_add_remove {
  align-items: center !important;
}
.badge-green.upbtn {
  border: 1px solid #666ba1 !important;
  color: #666ba1 !important;
  background: transparent !important;
}
.badge-green.upbtn .green_dot {
  background: #fff !important;
}
.badge-green.upbtn span {
  color: #666ba1 !important;
}
span.badge.infobtn.badge-blue {
  background: #31874a;
}

/* .............v......media */
@media (max-width: 767px) {
  .grid_img_div img,
  .profimg {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .profimg img {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    border-radius: 50%;
  }
  .grid_img_div {
    grid-template-columns: 65px calc(97% - 70px);
  }
}
@media (min-width: 767px) {
  tr {
    font-size: 18px;
  }
  .formhead {
    font-size: 40px;
  }
  .lightblue .card-footer p {
    font-size: 20px !important;
  }
}

/* ...............dark-theme............. */
/* .dark_theme .dd_meu_header a,.dark_theme .ongoing_sec a.nav-link.active,.dark_theme .ongoing_sec a.nav-link p,
.dark_theme .text-blue.formhead,.dark_theme .banner_title  {
  color: #fff !important;
}
.dark_theme .lightblue {
  background: #fff000 !important;
}
.dark_theme #header{
  border-bottom: 1px solid #505050;
}
.dark_theme .hr_yellow {
  border-top: 1px solid #505050 !important;
} */

.dropbtn.active,
.dropbtn.active {
  color: #16f9b2 !important;
  font-weight: 600;
}

/* NEW CSS */
.dropdown_yelo.btn_globe {
  background: transparent !important;
  border: none !important;
  fill: #1e24b9;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.btn_settings.btn_globe {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.dd_content_lang {
  right: 50% !important;
  left: 50%;
  transform: translate(-50%, 65%);
  border-radius: 4px !important;
  border: none !important;
  box-shadow: rgb(25 19 38 / 10%) 0px 2px 12px -8px,
    rgb(25 19 38 / 5%) 0px 1px 1px !important;
  padding: 16px !important;
  max-height: 400px;
  width: max-content !important;
  min-width: 128px !important;
}
.dd_content_lang a {
  min-height: 32px;
  height: auto;
  color: #1e24b9;
  padding: 0px 8px;
  border-radius: 8px;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  background-color: transparent;
  box-shadow: none;
  text-align: center;
  width: 100% !important;
}

.dd_content_lang a:hover {
  background: transparent !important;
  color: #1e24b9 !important;
  opacity: 0.65 !important;
}
.settings_modal .modal-dialog {
  max-width: 420px;
  /* max-width: 420px;
  width: auto;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto; */
}
.dropdown_yelo.dropdown_yelo_mdl {
  background: transparent !important;
  border: none !important;
}
.dropdown_yelo.dropdown_yelo_mdl li {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 14px;
  background-color: rgb(246, 247, 255);
  box-shadow: none;
  color: #1e24b9;
  margin-top: 4px;
  margin-right: 4px;
}
.dropdown_yelo.dropdown_yelo_mdl li:hover,
.dropdown_yelo.dropdown_yelo_mdl li.active:hover {
  opacity: 0.65 !important;
  color: #1e24b9 !important;
}

.dropdown_yelo.dropdown_yelo_mdl li.active {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 14px;
  background-color: #1e24b9;
  color: white !important;
  margin-top: 4px;
  margin-right: 4px;
}

.dropdown_yelo.dropdown_yelo_mdl li.active a {
  color: #fff !important;
}
.dropdown_yelo.dropdown_yelo_mdl li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  color: #1e24b9;
}
.modal_content_title {
  color: #1e24b9;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.modal_content_title_sm {
  color: #1e24b9;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 12px;
}

.modal-body {
  padding: 24px 17px 20px 17px !important;
}
.btn_settings.btn.btn_globe {
  fill: #16f9b2;
}
.modal-backdrop {
  /* background-color: #0a0012 !important; */
  background-color: #0f0d1a !important;
  transition: opacity 0.4s ease 0s;
  opacity: 0.6;
}
.modal-backdrop.show {
  opacity: 0.5 !important;
}
.modal-content {
  border: none !important;
}
.modal-header .close:hover {
  opacity: 0.65 !important;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 342px !important;
    max-width: 342px !important;
  }
}

.modal-header .sec-head {
  margin-top: 0px !important;
}
/* .light_theme .side-head-li
{
  color: #848484 !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 14px !important;
} */
.hoverbtn:hover {
  background: rgb(246, 247, 255);
  border-radius: 16px;
  opacity: 1 !important;
}
.dropdown.hoverbtn:hover .dropbtn {
  opacity: 1 !important;
}
.dropdown.hoverbtn:hover .dropbtn:hover {
  color: #16f9b2 !important;
}
.hoverbtn .dropbtn {
  padding: 11.5px 11px !important;
}
.btn_globe.hoverbtn .dropbtn {
  padding: 0px !important;
}
.dropdown_yelo.btn_globe .dropbtn:hover {
  opacity: 0.65 !important;
}
.light_theme #header {
  height: 56px !important;
}
.container-fluid#mobileshow {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.ml-15-px {
  margin-left: 15px !important;
}
/* .dropdown.hoverbtn
{
  height: 52px;
}
.dropdown.headerlinkbtn.hoverbtn button
{
  margin-top: 2px;
} */

@media only screen and (max-width: 991px) {
  .web_mobile_menu {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 991px) {
  .logo_img_sm {
    max-width: 100px !important;
  }
}
#header1 {
  position: fixed;
  top: 0px;
  left: 0px;
  transition: top 0.2s ease 0s;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 70px;
  /* background-color: #140026; */
  background-color: #03060a;
  background-image: url(../images/bg.png);
  background-size: cover;
  /* border-bottom: 1px solid rgb(231, 227, 235); */
  z-index: 20;
  transform: translate3d(0px, 0px, 0px);
}

@media only screen and (max-width: 400px) {
  #mobileshow1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .dropdown.hoverbtn:hover .dropdown-content {
    left: 0px !important;
    right: unset !important;
    width: 210px !important;
  }
  header .get-started-btn {
    padding: 2px !important;
    font-size: 13px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  header .get-started-btn.btn_value_header_addr {
    padding: 5px 10px !important;
    width: unset !important;
    height: 35px !important;
    border-radius: 0px !important;
  }
  .btn_globe,
  .logo_mob_mar {
    margin-right: 0.2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .nav_green {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    justify-content: flex-start !important;
    max-width: 100% !important;
    min-height: 60px;
  }
  .nav_green .nav-link {
    white-space: nowrap !important;
  }
  .dd_content_lang {
    transform: translate(-50%, 200%) !important;
  }
  .dropdown.hoverbtn:hover .dropdown-content {
    bottom: 60px !important;
    top: unset !important;
  }
}
.wallet_desc_blue {
  color: #1e24b9;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: center;
}
.themebtn {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  background-color: #1e24b9;
  color: rgb(255, 255, 255);
  width: 100%;
}
.mod_pad_space {
  padding: 24px;
}
.banner_subtitle {
  font-weight: 600;
  line-height: 1.1;
  font-size: 24px;
  color: #fff;
}
.label_input {
  font-size: 12px;
  color: #727272;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 5px;
}
.get-started-btn.btn_rect {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex !important;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 40px;
  padding: 0px 24px;
  background-color: transparent;
  border: 2px solid #16f9b2;
  box-shadow: none;
  color: #1e24b9;
}
.mt_18_px {
  margin-top: 18px !important;
}
.get-started-btn.btn_rect:hover {
  opacity: 0.65 !important;
  background-color: transparent !important;
  background: transparent !important;

  border: 2px solid #16f9b2;
  color: #1e24b9;
}
.col_btn_landing {
  justify-content: flex-end;
  display: flex;
}
.btn_grp_yel {
  justify-content: space-between;
}
.btn_rect_load {
  max-width: 100% !important;
  width: unset !important;
}
.light_theme .dd_meu_header.dd_coin_landing {
  left: 0px !important;
}
@media only screen and (max-width: 575px) {
  .apexcharts-canvas::before {
    width: 50px !important;
    height: 50px !important;
    background-size: 50px !important;
  }
  header .get-started-btn {
    padding: 2px !important;
    font-size: 13px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  .wallet-lists-new-m .liquid_raised {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .wallet-lists-new-m .wal-option .side-head-li {
    font-size: 12px !important;
  }
  .dropdown_normal button {
    min-width: 110px !important;
  }
  .row_left_res .text-right {
    text-align: left !important;
  }
  .currency_badge {
    padding: 2px 8px !important;
  }
  .card_footer_form .d-flex {
    display: block !important;
  }
  .card_footer_form .d-flex .pl-3 {
    padding-left: 0px !important;
  }
  .card_footer_form .d-flex .fa {
    margin-bottom: 10px !important;
  }
  .white_txt_sm.d-flex {
    flex-direction: column !important;
    gap: 10px;
  }
  .row_frist_home .col-12 {
    margin-bottom: 4.3rem !important;
  }
  .banner_title {
    font-size: 40px !important;
  }
  .light_theme .dd_meu_header.dd_coin_landing {
    min-width: 100% !important;
    left: 0px !important;
  }
  .mt_18_px {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .flex_badges_dv.flex_badges_dv_new {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
  }
  .inside_soc_f {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* .btn4_div .get-started-btn
  {
    padding: 0px 11px !important;
    font-size: 13px !important;
  } */
  .row_left_res.row_left_res_new {
    display: block !important;
  }
  .row_left_res.row_left_res_new .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .row_left_res.row_left_res_new .col-12 .text-right-res-new {
    text-align: left !important;
    margin-top: 12px !important;
  }
  .webr_res_div.d-flex {
    display: block !important;
  }
  .img_ind_circle {
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
  }
  .profimg {
    max-width: 50px !important;
    max-height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
  }
  .profimg img {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    border-radius: 50%;
  }
  .webr_res_div .countdown,
  .webr_res_div .countdown_grey {
    text-align: center;
  }

  .webr_res_div .countdown div {
    justify-content: center !important;
  }
  .webr_res_div .pb_card_buy {
    text-align: center;
    padding-bottom: 20px;
  }
  .grid_img_div {
    grid-template-columns: 60px calc(97% - 60px) !important;
  }
  .profimg {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    border-radius: 50%;
  }
  .profimg img {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    border-radius: 50%;
  }
  .grid_img_div img {
    max-width: 40px !important;
    max-height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }
}
.text-black-head {
  color: #fff !important;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize !important;
}
.text-uppercase.text-black-head {
  text-transform: capitalize !important;
}
.table_heade_title {
  font-size: 24px;
  color: #1e24b9;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.1;
}
.border-rad-15 .table_style_1 tr:first-child td {
  border-top: none !important;
}
.get-started-btn-fill.orangebtn {
  background: #e78638 !important;
  border-color: #e78638 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.orangebtn:hover {
  color: #fff !important;
  border-color: #e78638 !important;
}
.lightblue .card-footer p.token_name_title {
  font-size: 24px !important;
  color: #1e24b9 !important;
}
.lightblue .card-footer p.token_desc_title {
  color: #1e24b9;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5;
}
.card_img_craete .card-footer {
  border: none !important;
}
.font_label_from_blue.yellow_txt {
  font-size: 12px !important;
  text-transform: uppercase;
  color: #1e24b9 !important;
  font-weight: 600 !important;
}
.input_desc_sm,
.light_theme .input_desc_sm {
  color: #fff !important;
  font-size: 12px !important;
  font-family: "Orbitron", sans-serif;
  font-weight: 500;
}
.light_theme .text_are_grp textarea {
  /* font-size: 16px !important; */
  /* color: #16f9b2 !important; */
  /* padding: 0.375rem 0.75rem; */
}

.countdown_grey {
  color: #8f8f8f !important;
  font-size: 13px !important;
}
.countup.countbtn .badge {
  background: transparent !important;
  border-color: #16f9b2 !important;
  color: #16f9b2 !important;
}

.modalbtn .inputs input::placeholder,
.light_theme .input-group input.form-control::placeholder {
  /* color: #1E24B9 !important; */
  font-size: 12px !important;
}
.light_theme .badge-yellow-fill-big.text-white,
.light_theme .badge-yellow-fill-big.text-white.btn-danger {
  background: #e78638 !important;
  border: 1px solid #e78638 !important;
  color: #fff !important;
  border-radius: 10px !important;
}
.light_theme .badge-yellow-fill-big.text-white:hover,
.light_theme .badge-yellow-fill-big.text-white.btn-danger:hover {
  background: #e78638 !important;
  background-color: #e78638 !important;

  border: 1px solid #e78638 !important;
  color: #fff !important;
  border-radius: 10px !important;

  opacity: 0.65 !important;
}

.form-control {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.searc_style_1 {
  font-size: 16px !important;
}
@media only screen and (min-width: 768px) {
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 65px !important;
  }
}
/* 
.headerlinkbtn.hoverbtn .dropbtn
{
  height: 52px !important;
} */
span.badge.infobtn.badge-blue.btn_timer {
  background: transparent !important;
  font-size: 12px !important;
  border: none !important;
}
span.badge.infobtn.badge-blue.btn_timer span {
  font-size: 12px !important;
}
span.badge.infobtn.badge-blue.btn_timer .green_dot {
  background: #16f9b2 !important;
}
span.badge.infobtn.badge-blue.btn_timer .green_txt {
  color: #16f9b2 !important;
}
.countdown {
  font-size: 12px !important;
}
/* END NEW CSS */

/* NIVITHA */
.footer_menu {
  display: none !important;
}
.lockdetail_table td:nth-child(4) {
  min-width: 150px;
}
.whole_sec.pb-5 {
  padding-bottom: 0px !important;
}
.badge_cur_modal a {
  color: #d6d6d6 !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.badge_cur_modal a:hover {
  color: #fff !important;
  font-weight: 400 !important;
}
.header_odal_head {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-bottom: none !important;
}
.modal-content .card_bg {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.btn_social_new_link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.topBar {
  padding-left: 30px !important;
}
.countdown_new .cout_man_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.cout_man_div div {
  background-color: #1e24b9;
  background: #1e24b9;
  /* background-image: linear-gradient(102deg, #F54200 30%, #F5AE00 77%); */
  color: #fff;
  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 0px;
  min-width: 38px !important;
  min-height: 38px !important;
  max-width: 38px !important;
  max-height: 38px !important;
  display: inline-block;
}
.link_grn_new {
  color: #ee9520 !important;
}
.link_grn_new:hover {
  color: #f56f27 !important;
}
.trans_cal_btn.btn_blk {
  background: #fff !important;
  border-color: #fff !important;
  color: #fff !important;
  font-size: 10px !important;
}
.trans_cal_btn.btn_blk:hover {
  background: transparent !important;
  color: #fff !important;
}

.trans_cal_btn {
  /* background: #140038 !important;
  border: 1px solid #1E24B9 !important; */
  background-color: #1e24b9 !important;
  /* background: #1E24B9; */
  border: 1px solid #1e24b9 !important;
  min-height: 42px;
  border: none;
  color: #fff;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.copy_hover {
  cursor: pointer;
}
.copy_hover:hover {
  color: #16f9b2 !important;
}
.card_bg_wizard {
  background-color: transparent !important;
  height: 100%;
  cursor: pointer;
}
.card_bg_wizard.active .wizard_badge_num,
.card_bg_wizard:hover .wizard_badge_num {
  background: #fff !important;
  opacity: 1 !important;
}
.card_bg_wizard.active .create_wiz_desc::before,
.card_bg_wizard:hover .create_wiz_desc::before {
  border-color: #fff !important;
}

.card_bg_wizard.active .create_wiz_desc h5,
.card_bg_wizard:hover .create_wiz_desc h5 {
  color: #fff !important;
  opacity: 1 !important;
}

.card_bg_wizard.active .create_wiz_desc p,
.card_bg_wizard:hover .create_wiz_desc p {
  color: #fff !important;
  opacity: 1 !important;
}

.currency_badge {
  background-color: transparent;
  border-radius: 0px !important;
  color: #d6d6d6 !important;
  padding: 7px 25px;
  font-size: 12px !important;
  cursor: pointer;
  /* background-image: url("../images/badge_bg.png"); */
  border: 1px solid #fff;
  background-size: 100% 100%;
}
.currency_badge:hover span,
.currency_badge.active span {
  background: -webkit-linear-gradient(#fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.currency_badge span {
  font-size: 13px;
  font-weight: 500;
  color: white;
}
.currency_badge:hover,
.currency_badge.active {
  /* background-color: #1E24B9; */
  background: #1e24b9;
  border-color: #1e24b9;
  color: #fff !important;
}
.custom-control-label {
  color: #d6d6d6 !important;
  font-size: 12px !important;
}
.custm_sel {
  background-color: transparent !important;
  background-image: url("../images/input_border.png");
  background-size: 100% 100%;
  /* background-position: left -1px top 0px; */
  border-radius: 0px !important;
  border: none !important;
  color: #d6d6d6 !important;
  font-size: 12px !important;
  min-height: 44px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1e24b9 !important;
  background-color: #1e24b9 !important;
  background: #1e24b9;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input .custom-control-label::before {
  border-radius: 0px !important;
  width: 10px;
  height: 10px;
}
.light_theme .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1e24b9 !important;
  background-color: #1e24b9 !important;
}
.custom-control-label {
  color: #fff;
}
.card_bg_steps {
  background: #1e24b9;
}
.custom-control-label span {
  color: #ffffffcf !important;
  font-weight: 400 !important;
}
.card_footer_form {
  background-color: #fff000e3;
  border: 1px solid #272523 !important;
  border-radius: 0px;
  color: #000;
  font-size: 11px;
  padding: 10px 10px;
  text-align: center;
}
.btn_fullwidth_grad {
  width: 100%;
  background: #1e24b9;
  height: unset !important;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.btn_text_head {
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Orbitron", sans-serif;
}
.btn_text_desc {
  font-size: 12px !important;
  font-weight: 300;
  font-style: italic;
}
.text-danger-war {
  color: #f00;
  font-size: 30px !important;
}
.wizard_badge_num {
  /* background-color: #1E24B9; */
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  background-color: #fff !important;
  opacity: 0.5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}
.create_wiz_desc {
  position: relative;
}

.create_wiz_desc::before {
  content: "";
  border-left: 1px solid #fff;
  height: calc(100% - 30px);
  position: absolute;
  top: 22.5px;
  left: -0.5rem;
}

.card_bg_steps .card_step:last-child .create_wiz_desc::before {
  border: none !important;
}

.card_bg_steps .card_step:first-child .card-body {
  padding-top: 30px !important;
}
.card_bg_wizard .card-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.card_bg_wizard .create_wiz_desc {
  /* padding-top: 20px; */
  padding-bottom: 20px;
}

.create_wiz_desc h5 {
  color: #fff;
  font-size: 14px !important;
  font-weight: 700;
  opacity: 0.5;
}
.create_wiz_desc p {
  color: #fff;
  font-size: 12px !important;
  padding-bottom: 20px;
  opacity: 0.5;
}
.btn_social {
  background-color: #120f50 !important;
  background: #120f50 !important;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #1e24b9;
  border: none !important;
}
.light_theme .btn_social {
  color: #1e24b9;
}
.btn_social_new {
  /* background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% ); */
  background: transparent linear-gradient(102deg, #fff 30%, #fff 77%) 0% 0%
    no-repeat padding-box;
  color: #1e24b9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none !important;
}
.light_theme .btn_social_new {
  /* background: linear-gradient(275deg, #6300DD 0%, rgba(49, 6, 103, 0.72) 100%); */
  background: transparent linear-gradient(102deg, #fff 30%, #fff 77%) 0% 0%
    no-repeat padding-box;

  /* background: transparent linear-gradient(289deg, #f54200 0%, #f5ae00 100%) 0% 0% no-repeat padding-box !important; */
}
.sale_deta_name {
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
}
.img_ind_circle {
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  border-radius: 50%;
}
.btn_social:hover {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid #1e24b9 !important;
  color: #1e24b9;
}
.btn_social_new:hover {
  background: #1e24b9 !important;
  border: 1px solid #1e24b9 !important;
  color: #fff;
}
.font_12 {
  font-size: 12px !important;
}
.hr_green {
  /* background-color: #1E24B9; */
  background-color: #1e24b9;
}
.light_theme .hr_green {
  background-color: #13002866;
}
.apexcharts-legend-text {
  color: #fff !important;
}

.badge-green-rect {
  background-color: #1e24b9;
  color: #fff;
  border-radius: 5px !important;
  position: relative;
  font-weight: 500 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 50px !important;
}
.badge-kyc-rect {
  background-color: #fea3e0;
  color: #fff;
  border-radius: 5px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 50px !important;
}
.badge_live {
  /* background-color: #1E24B9; */
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
  font-family: "Orbitron", sans-serif;
}
.badge_live::before {
  position: absolute;
  /* left: 0px; */
  top: 10px;
  background-color: green;
  width: 8px;
  height: 8px;
  left: -5px;
  border-radius: 50%;
  content: "";
}
.badge_upcoming {
  /* background-color: #ff4409;
  background-image: linear-gradient(45deg, #5957ef,#1d97e0,#ee9520);
   */
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
  font-family: "Orbitron", sans-serif;
}
.badge_upcoming::before {
  position: absolute;
  /* left: 0px; */
  top: 10px;
  background-color: #fff;
  width: 8px;
  height: 8px;
  left: -5px;
  border-radius: 50%;
  content: "";
}
.badge_finish {
  /* background-color: #F54200; */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%); */
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
  font-family: "Orbitron", sans-serif;
}
.badge_finish::before {
  position: absolute;
  /* left: 0px; */
  top: 10px;
  background-color: #cd0101;
  width: 8px;
  height: 8px;
  left: -5px;
  border-radius: 50%;
  content: "";
}
.badge_cancel {
  background-color: #9e9e9e;
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
}
.flex_side_right {
  display: flex;
  justify-content: end;
}
#sidebar {
  min-width: 250px;
  min-height: calc(100vh - 55px);
  /* max-height: calc(100vh - 55px); */
  overflow-y: auto;
  /* background-color: #130027; */
  /* background: #0A071E 0% 0% no-repeat padding-box; */
  position: absolute;
  top: 70px;
  z-index: 1;
}
#sidebar.side_shrink {
  min-width: 20px;
}
#sidebar.side_shrink .lis_paren_side span,
#sidebar.side_shrink .lis_paren_side_accordion span,
#sidebar.side_shrink .btn_white_outline_round,
#sidebar.side_shrink .mode_taxt,
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
  display: none;
  min-width: 20px !important;
}
#sidebar.side_shrink .inner_card_body_acc {
  position: fixed;
  left: 50px !important;
  background-color: #06090d !important;
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin-top: -30px;
  /* top:0px !important; */
}

#sidebar.side_shrink .inner_card_body_acc .sidebar_inner_ul {
  padding-right: 1.5rem !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
  max-width: 30px !important;
  min-width: 30px !important;
}

#sidebar.side_shrink .lis_paren_side:hover {
  min-width: 30px;
  max-width: 30px;
  padding-left: 7px;
}
#sidebar.side_shrink .footer_theme_div {
  left: 3px !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover button {
  padding-right: 0px !important;
}

#sidebar.side_shrink .shrink_side_btn {
  left: 10px !important;
}

#sidebar.side_shrink .sidebar_ul {
  margin-left: 5px !important;
}
.shrink_side_btn {
  position: fixed;
  left: 235px;
  top: 50vh;
}
.right_side_sec {
  width: calc(100% - 250px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.right_side_spacing {
  padding-left: 30px;
  padding-right: 30px;
}
.sidebar_shr .right_side_sec {
  width: calc(100% - 50px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.sidebar_ul {
  padding-top: 50px !important;
  padding-left: 0px !important;
  margin-left: 10px;
  margin-right: 10px;
}
.sidebar_ul li {
  list-style-type: none !important;
  padding: 5px 10px;
  margin-bottom: 20px;
  /* max-width: fit-content; */
}
.li_bot_less {
  margin-bottom: 10px !important;
}
.sidebar_ul li img {
  max-width: 30px;
}
.sidebar_ul span {
  color: #fff;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
}
.sidebar_inner_ul a {
  font-size: 13px;
  color: #fff !important;
}
#sidebar hr {
  border-color: #9f9b9bad !important;
}
#sidebar .accordion .card {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

#sidebar .accordion .card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#sidebar .accordion .card button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #fff !important;
  text-decoration: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.sidebar_inner_ul a {
  color: #d6d6d6 !important;
}
.sidebar_inner_ul a:hover,
.sidebar_inner_ul a.active {
  color: #4950ff !important;
}
.btn_white_outline_round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* border:1px solid #fff; */
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
}
.btn_white_outline_round .fa {
  margin-top: 2.5px;
}
.lis_paren_side {
  max-width: 165px;
}
.dashimg {
  /* border: 1px solid #1E24B9; */
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: -12%;
  /* left: 35%; */
  transform: translate(-50%, -50%);
  max-width: 30%;
  background: #0a071e 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #1E24B9; */
  box-shadow: 0px 0px 6px #1e24b9;
}
.light_theme .dashimg {
  background-color: #fff;
}
.parent_1_grad.active .lis_paren_side {
  /* background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% ); */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff;
  border-radius: 0px 10px 10px 0px;
}
.light_theme .parent_1_grad.active .lis_paren_side {
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff;
  border-radius: 0px 10px 10px 0px;
}
.lis_paren_side:hover {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.2) 100%); */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff !important;
  border-radius: 0px 10px 10px 0px;
}
.light_theme .lis_paren_side:hover {
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff !important;
  border-radius: 0px 10px 10px 0px;
}
.lis_paren_side_accordion:hover .icon_text_div {
  /* background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% ); */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
.light_theme .lis_paren_side_accordion:hover .icon_text_div {
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #4950ff;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
.lis_paren_side_accordion .icon_text_div {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.theme_icon,
.text_splash {
  color: #d6d6d6 !important;
  font-size: 13px !important;
}
.theme_icon {
  cursor: pointer;
}
.theme_icon:hover {
  color: #4950ff !important;
}
.footer_theme_div {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.hr_yellow {
  /* border-color: #1E24B9 !important; */
  border-color: #4950ff !important;
}
.light_theme .hr_yellow {
  border-color: #f0dffd !important;
}
.nav_green {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav_green .nav-link.active,
.nav_green .nav-link:hover {
  background: transparent !important;
  color: #22f300 !important;
}
.nav_green .nav-link {
  /* background-color: #0a071e !important; */
  color: #fff !important;
  /* margin-left: 10px; */
  padding-left: 0px;
  margin-right: 10px;
  border-radius: 0px !important;
  /* box-shadow: 0px 1px 9px -1px #1E24B9 !important; */
  font-size: 13px !important;

  background: transparent !important;
  /* box-shadow: 0px 0px 6px #1E24B9; */
}
.dropdown_normal button {
  /* background-color: #13003a   !important; */
  background: #04030e 0% 0% no-repeat padding-box !important;
  border-radius: 0px !important;
  border-color: #04030e !important;
  color: #ffff !important;
  font-size: 13px !important;
  box-shadow: 0 0 0 0rem rgb(72 180 97 / 50%) !important;
  min-width: 120px !important;
  width: 100%;
  padding: 10px 13px;
  text-align: left !important;
}
.dropdown_normal_menu {
  background-color: #04030e !important;
  border-radius: 0px !important;
  min-width: 120px !important;
  text-align: left !important;
  color: #fff !important;
  width: 100%;
}
.dropdown_normal_menu a {
  color: #fff !important;
  font-size: 13px !important;
}
.dropdown_normal_menu .dropdown-item:hover,
.dropdown_normal_menu .dropdown-item:focus,
.dropdown_normal_menu .dropdown-item:active,
.dropdown_normal_menu .dropdown-item:focus-visible {
  background-color: #1e24b9 !important;
}
.searc_style_2 {
  /* background-color: #13003a  !important; */
  background: #04030e 0% 0% no-repeat padding-box !important;
  border-radius: 0px !important;
  color: #fff !important;
  border-color: #04030e !important;
  font-size: 13px !important;
  padding: 10px 12px !important;
  height: unset !important;
}
.searc_style_2::placeholder {
  color: #727272 !important;
  font-size: 12px !important;
}
/* THEME CSS */
.whole_bg {
  background-image: url(../images/bg.png);
  background-size: cover;
  background-color: #0a071e !important;
}
.whole_sec {
  background-image: url(../images/hero_banner_full.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  background-attachment: scroll;
  /* background-color: #0A071E !important; */
}
.yellow_text_sml {
  color: #1e24b9;
  text-transform: uppercase;
  font-family: "Orbitron", sans-serif;
  font-size: 12px;
}
/* .whole_sec::before
{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 3%);
} */
/* body.dark_theme #header.header-scrolled
{
  background: #0a071e 0% 0% no-repeat padding-box !important;
} */
/* body.dark_theme #header
{
  background: #0a071e 0% 0% no-repeat padding-box;

} */
/* body.dark_theme .right_side_sec
{
  background: #0a071e 0% 0% no-repeat padding-box !important;
} */
.dark_theme .card_style_1 .card-body {
  /* background-color: #12003C !important; */
  background: #1e24b9 0% 0% no-repeat padding-box !important;
}

.dark_theme .card_style_1 {
  /* background-color: #0a071e !important; */
  background: #fff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #1E24B9; */
}
/* END THEME CSS */
/* END NIVITHA */

/* kr css */
.filter_dropdown {
  display: inline-flex !important;
}
.filter_dropdown .dropdown-toggle {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  color: #727272 !important;
}
.filter_dropdown .dropdown-toggle::after {
  content: none !important;
}
.table_filter_icon {
  box-shadow: 0 0 0 0 rgb(38 143 255 / 50%) !important;
}
.table_dropdown_menu {
  background-color: #04030e !important;
  max-height: 150px !important;
  overflow-y: auto !important;
}
.table_dropdown_menu a {
  color: #727272 !important;
}
.table_dropdown_menu a:hover {
  background-color: #fff !important;
  color: #fff !important;
}
.table_dropdown_menu a:last-child:hover {
  background-color: #373737 !important;
  color: #727272 !important;
}
.fa-filter {
  font-size: 12px !important;
}
.relative_token_div {
  max-height: 30px;
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
  background: #0a071e;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #565656;
}
.absolute_token_div {
  margin-left: -10px;
  max-height: 30px;
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
  background: #0a071e;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #565656;
}
.trendss a {
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
}
.trendss a span {
  color: #fff !important;
}
.trendss a:hover,
.trendss a:hover span {
  color: #1e24b9 !important;
}

.light_theme .trendss a span {
  color: #a29d9d !important;
}
.light_theme .trendss a:hover,
.light_theme .trendss a:hover span {
  color: #1e24b9 !important;
}
.token_image_sm {
  height: 20px !important;
  width: 20px !important;
}
::-webkit-scrollbar {
  height: 6px;
  width: 5px;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #1e24b9;
  border-radius: 6px;
}
.topBar {
  padding: 10px 30px;
  overflow-x: auto !important;
  /* border-bottom: 1px solid #1E24B9; */
  align-items: center;

  /* background: linear-gradient(270deg, #2A015B 0%, #120028 100%); */
  /* background: #0A071E 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 12px 16px 1px rgba(0, 0, 0, 0.9); */
}
.topBar small {
  margin-right: 30px !important;
}
.title_span {
  font-size: 13px;
  color: #fff;
}
.topBar span {
  color: #1e24b9 !important;
}
.liquid_raised {
  position: relative;
  height: 100% !important;
  padding: 35px 10px;
  /* background: #12003C; */
  background: #fff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 0px 6px #42414f; */
  border-radius: 10px !important;
  cursor: pointer;
  font-family: "Orbitron", sans-serif;
  font-size: 12px;
}
.liquid_raised .font15 {
  color: #1e24b9;
  /* color: #fff; */
}
.h3_res {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
  font-family: "Orbitron", sans-serif;
}
.h5_res {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  font-family: "Orbitron", sans-serif;
}
.liquid_raised:hover,
.card_bg:hover,
.card_sec_image:hover,
.card_home_style_2:hover {
  box-shadow: 0px 1px 9px -2px #76ab9e !important;
  /* background: #0a071e; */
}
.icon_align {
  position: absolute;
  padding: 15px;
  top: -40px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  min-width: 65px;
  background-color: #0a071e;
  border-radius: 50%;
  box-shadow: 0px 1px 9px -1px #16f9b2 !important;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  /* display: none; */
}
.icon_align img {
  max-width: 100%;
}
.card_bg {
  cursor: pointer;
  /* background: #12003C !important; */
  background: #000 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
}
.card_img_pare_div {
  background-color: #1e24b9;
  /* background: #1E24B9; */
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content .card_bg {
  box-shadow: 0px 0px 0px 0px #16f9b2 !important;
}
.no_data_txt {
  color: #fff;
  font-size: 18px !important;
  font-weight: 600;
  text-align: center;
}
.card_img_pare_div img {
  max-width: 70px;
  max-height: 70px;
  padding: 4px;
  object-fit: contain;
}
.bottom_text {
  /* color: #1E24B9; */
  color: #fff;
  font-weight: 500;
  font-size: 11px;
}
.sub_text {
  color: #d6d6d6;
  font-size: 12px;
}
.font15 {
  color: #d6d6d6;
  font-size: 15px;
}

.green_icons {
  color: #16f9b2;
  margin-right: 6px;
  font-size: 15px;
}
.green_icons:hover {
  color: #317fe6 !important;
}
.close_icons i {
  margin-right: 6px;
  color: #727272;
}
.table_time {
  color: #fff;
}
.progressBar {
  background-color: #fff !important;
  width: 70px;
}
.token_image {
  height: 20px;
  width: 20px;
}
.input_group {
  background: #04030e 0% 0% no-repeat padding-box !important;
  color: #727272 !important;
  border-radius: 0px;
  padding: 0px 5px 0px 0px !important;
  height: 42px;
  border: none !important;
  outline: none !important;
}

.search_bar {
  min-width: 160px !important;
}
.table tr {
  /* background-color: #12003C    !important; */
  /* background: #04030e 0% 0% no-repeat padding-box !important; */
  color: #fff !important;
}
.table tr:first-child {
  background-color: unset !important ;
}
.table {
  background-color: transparent !important;
}
.table tr {
  border-block-end: 1px solid #f1a00480;
}
.table tr:first-child {
  border-block-end: 0px solid #0a071e;
}
.table.affilair_table tr:nth-child(odd) {
  background-color: transparent !important ;
}
.table.affilair_table tr:nth-child(even) {
  background-color: #04030e !important ;
}
.table th {
  border: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.table td {
  font-size: 12px;
  border: none !important;
  vertical-align: middle !important;
}
.table th:first-child {
  min-width: 160px;
}
.table th:nth-child(2) {
  min-width: 120px;
}
.table th:nth-child(3) {
  min-width: 100px;
}
.table th:nth-child(4) {
  min-width: 120px;
}
.table th:nth-child(5) {
  min-width: 130px;
}
.table th:nth-child(6) {
  min-width: 130px;
}
.table th:nth-child(7) {
  min-width: 90px;
}
.table th:nth-child(8) {
  min-width: 120px;
}
.table th:last-child {
  min-width: 140px;
}
.table td {
  border-bottom: 10px #1e24b9 !important;
}
.recepients_table td:first-child {
  min-width: 160px;
  min-height: 53px;
  align-items: center;
}
.dark_theme .bordertheme {
  background: #fff;
  border-radius: 0px;
  padding: 8px 16px;
}
.light_theme .bordertheme {
  background: #fff;
  border-radius: 0px;
  padding: 8px 16px;
  box-shadow: 0px 1px 9px -1px #1e24b9 !important;
}
.recepients_table td:nth-child(3) {
  min-width: 100px;
  min-height: 53px;
  align-items: center;
}
.typebtn {
  width: 110% !important;
}
.table td:nth-child(5) {
  min-width: 130px;
}
.link_text {
  color: #1e24b9 !important;
  cursor: pointer;
}
.link_text_white {
  color: #fff !important;
  cursor: pointer;
}
.link_text_white:hover {
  color: #f54200 !important;
}
.link_text:hover {
  color: #f54200 !important;
}
.active_times {
  color: #317fe6 !important;
}
.times_hover:hover {
  color: #317fe6 !important;
  cursor: pointer;
}
.input_group input {
  font-size: 12px !important;
  /* padding-bottom: 10px; */
  color: #fff !important;
  border: none !important;
  outline: none !important;
}
.input_group.search_bar input::placeholder {
  color: grey !important;
}
.coming_soon {
  width: 100%;
  min-height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.common_search {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.list_sec {
  text-align: end;
}
.list_sec ul {
  list-style-type: none;
  display: inline-flex;
  color: #fff !important;
}
.list_sec ul li:first-child {
  margin-right: 10px;
}
.active {
  color: #1e24b9 !important;
}

.absolute_image {
  margin-left: -10px;
}
.table-responsive::-webkit-scrollbar {
  height: 6px;
  border-radius: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  background: black;
  border-radius: 6px;
}
.table-responsive::-webkit-scrollbar-thumb {
  /* background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% ); */
  background: transparent linear-gradient(102deg, #f54200 30%, #f5ae00 77%) 0%
    0% no-repeat padding-box;
  border-radius: 6px;
}
.table_text {
  font-size: 12px;
  color: #fff;
  padding: 0;
}
.sub_tabletext {
  font-size: 10px;
  color: #fff;
  padding: 0;
}
.table_det {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table_nav .nav-link.active,
.table_nav .nav-link:hover {
  /* color: #1E24B9 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #1E24B9 !important; */
  color: #1e24b9 !important;
  background-color: transparent !important;
  /* border-bottom: 1px solid #1E24B9 !important; */
  padding: 0 !important;
}
.light_theme .table_nav .nav-link.active,
.light_theme .table_nav .nav-link:hover {
  color: #519f26 !important;
  border-bottom: 1px solid #519f26 !important;
}
.table_nav .nav_link {
  color: #fff !important;
  padding: 0 !important;
  font-size: 12px !important;
  margin-right: 25px !important;
  border-bottom: 1px solid transparent !important;
}
.light_theme .table_nav .nav_link {
  color: black !important;
}
.pagination_btn {
  padding: 5px 10px;
  background-color: transparent;
  color: #fff !important;
  border-radius: 0px;
  border: 1px solid #fff !important;
  min-width: 30px !important;
  margin: 2px;
  min-height: 30px !important;
}
.pagination_btn:hover {
  background-color: #1e24b9;
  background: linear-gradient(180deg, #f54200 0%, #f5ae00 100%);
  color: white;
  border: 1px solid #1e24b9 !important;
}
.pagination_btn.active {
  /* background-color: #1E24B9;
  background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% );
  color: white; */
  background-color: #1e24b9;
  background: linear-gradient(180deg, #f54200 0%, #f5ae00 100%);
  color: white;
  border: 1px solid #1e24b9 !important;
}
.wallet-modal .modal-dialog {
  border-radius: 10px !important;
}

/* .modal_header{
  background-color: #0a071e !important;
} */

.copy_icon {
  color: #317fe6;
  cursor: pointer;
}
.copy_icon:hover {
  color: #fff;
}
@media only screen and (min-width: 1200px) {
  .newfooter .container {
    max-width: 100% !important;
  }
  .newfooter .left {
    padding-left: 80px;
  }

  .newfooter .overlay {
    padding-left: 80px;
  }
}

/* end kr css */

/* sidebar css */
.title_lockinfo {
  color: #8f8f8f !important;
  font-weight: 600 !important;
  font-size: 15px;
}
.lockdetail_table td:nth-child(4) {
  min-width: 150px;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  #sidebar.side_shrink .lis_paren_side:hover {
    min-width: 150px !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover button {
    padding-left: 0px !important;
  }
  #sidebar {
    position: fixed !important;
    z-index: 1000 !important;
    max-height: calc(100vh - 20px) !important;
    min-height: calc(100vh - 20px) !important;

    overflow-y: auto !important;
    min-width: 0px;
    max-width: 0px;
  }
  .footer_menu_flex_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer_menu_flex_side.footer_menu_flex_side_admin {
    justify-content: flex-end !important;
  }

  .btn_bar_mobl {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
  }
  /* #sidebar .lis_paren_side span,
#sidebar .lis_paren_side_accordion span,
#sidebar .btn_white_outline_round,
#sidebar .mode_taxt,
#sidebar .lis_paren_side_accordion:hover .icon_text_div span
{
display: none;
min-width: 20px !important;
} */
  #sidebar .sidebar_ul {
    margin-left: 0px !important;
  }
  .sidebar_ul {
    margin-right: 0px !important;
    padding-left: 5px !important;
  }
  /* #sidebar
{
  max-width: 50px !important;
} */

  #sidebar .shrink_side_btn {
    left: 35px !important;
  }
  #sidebar .side_shrink {
    min-width: 250px;
    min-height: calc(100vh - 55px);
    max-height: calc(100vh - 55px);
    overflow-y: auto;
    background-color: #0a071e;
    position: sticky;
    top: 55px;
    z-index: 1;
  }
  #sidebar.side_shrink .shrink_side_btn {
    position: fixed;
    left: 235px !important;
    top: 50vh !important;
  }
  .side_shrink {
    min-width: 250px !important;
  }
  .right_side_sec {
    /* width: calc(100% - 50px); */
    width: calc(100% - 0px);

    margin-left: 0px;
  }

  #sidebar.side_shrink .lis_paren_side span,
  #sidebar.side_shrink .lis_paren_side_accordion span,
  #sidebar.side_shrink .mode_taxt,
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
    display: inline !important;
    min-width: 20px !important;
  }
  #sidebar.side_shrink .btn_white_outline_round {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 15px !important;
    height: 15px !important;
    max-width: 15px !important;
    max-height: 15px !important;
    min-width: 15px !important;
    min-height: 15px !important;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 12px !important;
  }
  /* #sidebar .inner_card_body_acc {
    position: fixed;
    left: 50px !important;
    background-color: #0a071e !important;
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px;
    margin-top: -30px;
} */
  #sidebar.side_shrink .accordion .card button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  /* #sidebar .footer_theme_div {
  left: 3px !important;
} */
  #sidebar.side_shrink .footer_theme_div {
    left: 20px !important;
  }
  #sidebar .inner_card_body_acc .sidebar_inner_ul {
    padding-right: 1.5rem !important;
  }
  #sidebar.side_shrink .accordion .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  /* #sidebar .inner_card_body_acc {
  position: fixed;
  left: 50px !important;
  background-color: #0a071e !important;
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin-top: -30px;
 
} */
  .light_theme #sidebar .inner_card_body_acc {
    background-color: #fff !important;
    box-shadow: 0px 1px 9px -1px #02020299 !important;
  }
  .light_theme #sidebar .collapse .inner_card_body_acc {
    box-shadow: 0px 0px 0px 0px #02020299 !important;
  }
  /* #sidebar .lis_paren_side_accordion:hover button {
  padding-right: 0px !important;
} */
  /* #sidebar .lis_paren_side_accordion:hover .icon_text_div {
  max-width: 35px !important;
  min-width: 35px !important;
} */
  #sidebar.side_shrink .inner_card_body_acc {
    position: unset !important;
    left: unset !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: unset !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
    min-width: 150px !important;
    min-height: 30px !important;
  }
}
/* end sidebar css */
@media only screen and (max-width: 575px) {
  /* .airlists ul li {
    display: unset !important;
} */
  .fklex_dircol {
    flex-direction: column-reverse;
  }
  .dropdown_normal button {
    min-width: 110px !important;
  }
  .grid_img_div img,
  .profimg {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .profimg img {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    border-radius: 50%;
  }
  .grid_img_div {
    grid-template-columns: 50px calc(97% - 50px) !important;
  }
  .text-right-xss {
    text-align: right !important;
  }
  .white_txt_sm .countbtn {
    text-align: center !important;
  }
  .pb_card_buy {
    padding-bottom: 20px !important;
    text-align: center !important;
  }
  .countdown_grey_cen {
    text-align: center;
  }
}
.text-right-xss {
  text-align: right !important;
  justify-content: flex-end !important;
}
.flex_cont_end_flex {
  justify-content: flex-end !important;
}
.btn_pagin_wrap {
  flex-wrap: wrap !important;
}
#header,
#header1,
#sidebar {
  box-shadow: 4px 1px 5px -2px #02020299 !important;
  /* border-right: 1px solid #1E24B9; */
  border-bottom: 1px solid #9f9b9bad;
}
#sidebar {
  border-right: 1px solid #1e24b9;
}
/* LIGHT THEME CSS */
.light_theme .logo_img_sm {
  background-image: url(../images/Bitdeal/lightlogo.png);
}
.light_theme #sidebar {
  /* background-color: #efe0ff !important;
  box-shadow: none !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #1e24b9 !important;
}
.light_theme .lis_paren_side_accordion {
  background-color: transparent !important;
}
.light_theme .sidebar_ul span {
  color: black;
  font-weight: 500;
}
.parent_1_grad.active .lis_paren_side span,
.parent_1_grad:hover .lis_paren_side span {
  color: #4950ff !important;
}
.light_theme .sidebar_ul li img {
  /* filter: brightness(0.3); */
  filter: invert(1);
}
/* .parent_1_grad.active .lis_paren_side img,.parent_1_grad:hover .lis_paren_side img,
.lis_paren_side_accordion:hover .icon_text_div img
{
  filter: brightness(0) invert(0);

} */
.lis_paren_side_accordion:hover .icon_text_div span {
  color: #4950ff;
}
.light_theme .sidebar_inner_ul a,
.light_theme .table_text,
.light_theme .sub_tabletext {
  color: black !important;
}
.light_theme .sidebar_inner_ul a:hover,
.light_theme .sidebar_inner_ul a.active {
  color: #4950ff !important;
}
.light_theme .liquid_raised,
.light_theme .card_style_1,
.light_theme .pagination_btn,
.light_theme .card_sec_image,
.light_theme .card_bg_robot {
  background-color: #fff !important;
  box-shadow: 0px 1px 9px -1px #1e24b9 !important;

  /* box-shadow: 0px 0px 6px #1E24B9 !important; */
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
}
.light_theme .card_home_style_2 {
  background-color: #fff !important;
}
.light_theme .card_sec_image,
.light_theme .card_bg_robot,
.light_theme .card_home_style_2 {
  border: 1px solid #fff !important;
  /* box-shadow: 0px 0px 0px #1E24B9 !important; */
  box-shadow: 0px 1px 9px -1px #1e24b9 !important;
}
.light_theme .pagination_btn:hover,
.light_theme .card_sec_image:hover,
.light_theme .card_bg_robot:hover,
.light_theme .card_sec_image:hover,
.light_theme .card_bg_robot:hover,
.light_theme .card_home_style_2:hover {
  box-shadow: 0px 1px 9px -2px #76ab9e !important;
}
.light_theme .card_img_head,
.light_theme .heading_card,
.light_theme .heading_pool_head {
  color: #fff;
}

.light_theme .card_img_subhead,
.light_theme .heading_pool_subhead {
  color: #333;
}
.light_theme .proj_bg .card_style_1 .card-body {
  background-color: #ffff !important;
}
.light_theme .icon_align,
.light_theme .card_bg,
.light_theme .card_img_pare_div,
.light_theme .btn_social,
.light_theme #header1 {
  background-color: #fff !important;
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
  border: none !important;
}
.light_theme .btn_social:hover {
  background: transparent linear-gradient(289deg, #1e24b9 0%, #1e24b9 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff;
}
.light_theme .sale_deta_name {
  color: #fff !important;
}
.light_theme .modal-content .card_bg {
  box-shadow: 0px 0px 0px 0px #16f9b2 !important;
  border: none !important;
}
.light_theme .liquid_raised .font15 {
  color: #130028 !important;
}
.light_theme .h3_res {
  color: #1e24b9 !important;
  font-weight: 600;
}
.light_theme .sub_text,
.light_theme .theme_icon,
.light_theme .text_splash {
  color: #544762 !important;
}
.light_theme .theme_icon.active {
  color: #1e24b9 !important;
}
.light_theme .topBar span {
  color: #650bca !important;
}
.light_theme .bottom_text {
  color: black !important;
  font-weight: 500;
}
.light_theme .currency_badge {
  background: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: black !important;
  font-weight: 600;
}
.light_theme .tab_img .card_bg:hover {
  background-color: #fff !important ;
}

.light_theme .badge_cur_modal a {
  color: #fff !important;
}
.light_theme .wallet-lists ul {
  border-bottom: 1px solid #b3c0bc !important;
}
.light_theme .card_bg_wizard.active,
.light_theme .card_bg_wizard:hover,
.light_theme .card_footer_form,
.light_theme .cout_man_div div,
.light_theme .table_dropdown_menu {
  /* box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  box-shadow: 0px 1px 6px -1px #1e24b9eb !important;
  background: #fff !important;
}
.light_theme .cout_man_div div {
  color: black !important;
  font-weight: 500;
}
.light_theme .yellow_bar {
  background-color: #e1e1e1 !important;
}
.light_theme .card_footer_form {
  color: black !important;
  font-weight: 500;
  border-color: #d0d0d0 !important;
}
.light_theme .create_wiz_desc h5,
.light_theme .create_wiz_desc p,
.light_theme .input_desc_sm,
.light_theme .input_desc_sm {
  color: black !important;
  font-weight: 500;
}
.light_theme .card_bg_wizard {
  border: none !important;
}
.light_theme .wizard_badge_num {
  /* background-color:#6a04da !important; */
  background: transparent linear-gradient(102deg, #f54200 30%, #f5ae00 77%) 0%
    0% no-repeat padding-box !important;
}
.light_theme .custom-control-label {
  color: black !important;
  font-weight: 500;
}
.light_theme .currency_badge.active,
.light_theme .currency_badge:hover {
  background-color: #1e24b9 !important;
  color: #fff !important;
}
.light_theme .input-groups .input-group,
.light_theme .custm_sel,
.light_theme .searc_style_2,
.light_theme .dropdown_normal button,
.light_theme .dropdown_normal_menu,
.light_theme .input_group {
  background-color: #ffff !important;
  box-shadow: rgb(74 74 104 / 0%) 0px 2px 2px -1px inset;
  border: 1px solid #ccc !important;
  color: black !important;
  font-weight: 500;
}
.light_theme .file_grp_input.input-group {
  border: 1px solid transparent !important;
}
.light_theme .dropdown_normal_menu .dropdown-item:hover {
  background-color: #5ea92c !important;
  color: #fff !important;
}
.light_theme .trans_cal_btn {
  background-color: #1e24b9 !important;
  color: #fff;
  border: 1px solid #1e24b9 !important;
}
.light_theme .input-groups .input-group input,
.light_theme .input-groups .input-group input::placeholder,
.light_theme .input-groups .input-group textarea,
.light_theme .input-groups .input-group textarea::placeholder,
.light_theme .input_group input,
.light_theme .input_group input::placeholder {
  color: black !important;
  font-weight: 500;
  font-size: 12px !important;
  background-color: #ffff !important;
}
.light_theme .input-group input {
  border: 0px solid #13002866 !important;
}
.light_theme .banner_subtitle {
  color: black !important;
}
.light_theme .nav_green .nav-link {
  background: #fff !important;
  color: black !important;
  font-weight: 600;
  /* box-shadow: none !important; */
  box-shadow: 0 3px 10px rgb(98 172 46 / 50%);
}
.light_theme .nav_green .nav-link.active,
.light_theme .nav_green .nav-link:hover {
  /* background: linear-gradient(275deg, #6300DD 0%, rgb(116 5 255 / 77%) 100%) !important; */
  background: transparent linear-gradient(289deg, #1e24b9 0%, #1e24b9 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff !important;
}
.light_theme .adv_tab .input_group {
  border: none !important;
}
.light_theme .table tr {
  background-color: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .table tr:first-child {
  background-color: unset !important;
}
.light_theme .table tr {
  border-block-end: 3px solid #f5faff !important;
  color: black !important;
  font-weight: 500;
}

.light_theme .table tr .yellow_bar {
  background-color: #e4e0e0 !important;
}
.light_theme .green_icons,
.light_theme .link_text {
  color: #1e24b9 !important;
}
.light_theme .green_icons:hover {
  color: #317fe6 !important;
}
.light_theme .link_text:hover {
  color: #1e24b9 !important;
}

.light_theme .table tr .yellow_bar .progress-bar {
  background-color: #0bc592 !important;
  background: linear-gradient(
    275deg,
    #6300dd 0%,
    rgb(116 5 255 / 77%) 100%
  ) !important;
}

.light_theme .table_dropdown_menu a:hover {
  background-color: #d9dee3 !important;
  color: #848484 !important;
}

.dark_theme .input-groups .input-group {
  border: 1px solid #fff;
}
.dark_theme .input-groups .input-group input.form-control::placeholder,
.dark_theme .input-groups .input-group input,
.dark_theme .input-groups .input-group input:focus {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background-color: transparent;
  /* background-image: url("../images/input_border.png"); */
  background-size: 100% 100%;
  /* background-position: left -1px top 0px; */
  border-radius: 0px !important;
  border: none !important;
}
.dark_theme .input-group input.form-control::placeholder {
  background: unset !important;
}
.custm_sel option {
  background-color: black !important;
}
.light_theme .custm_sel option {
  background-color: #fff !important;
}
/* END LIGHT THEME CSS */
.table_dropdown_menu a:last-child:hover {
  background-color: transparent !important;
}
.lis_paren_side_accordion {
  border: none !important;
}
.wallet-lists ul li:hover .img {
  transform: scale(1.1);
  transition: 0.2s;
}
.react-datepicker-popper {
  z-index: 4 !important;
}
.logo_grad_width {
  max-width: 150px !important;
}
.coming_soon.login_text {
  text-align: left !important;
}
.file_grp_input .custom-file {
  /* border: 1px solid #04030e; */
  border-radius: 0px !important;
  /* background-color: #04030e;
  background: #04030e !important; */
  min-height: 42px !important;
  width: 100% !important;

  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  color: #d6d6d6;
  font-size: 14px !important;
}
.file_grp_input .custom-file-label {
  /* background-color: #140038;
  background: #140038 !important; */
  background: transparent !important;
  border: 1px solid #fff !important;
  /* background: #04030e 0% 0% no-repeat padding-box !important; */
  min-height: 42px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 0px;
  font-weight: 500;
  border: 1px solid #04030e !important;
}
.file_grp_input .custom-file-label::after {
  position: absolute;
  right: 10px;
  top: 10px;
  max-height: 20px !important;
  min-height: 20px !important;
  font-size: 12px;
  border-radius: 0px !important;
  font-weight: 600;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000 !important;
  background-color: #fff !important;
  /* background: #1E24B9; */
  border: 1px solid #fff !important;
  font-size: 11px !important;
}
.file_grp_input .custom-file-label::after:hover {
  background-color: transparent !important;
  color: #fff !important;
}
.custom-file-input {
  box-shadow: 0 0 0 0px rgb(0 123 255 / 25%) !important;
}

.icon_align_border {
  background-color: #fff;
  min-width: 190px;
  min-height: 150px;
  max-width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 9px -2px #16f9b2 !important;
  background: #0a071e;
  max-height: 150px;
  /* overflow: hidden; */
  object-fit: contain;
  aspect-ratio: 1/1;
}

.light_theme .file_grp_input .custom-file-label {
  background: transparent !important;
  border-color: #ccc !important;
  color: black !important;
  font-weight: 500;
}
.light_theme .file_grp_input .custom-file-label::after {
  background-color: #1e24b9 !important;
  background: #1e24b9 !important;
  color: #000 !important;
}
.light_theme .file_grp_input .custom-file {
  border-color: black !important;
}
.light_theme .icon_align_border {
  background-color: #ffffff !important;
  box-shadow: 0px 1px 9px -1px #02020299 !important;
}
.close_icons {
  margin-left: 10px;
}
.bold_text_proof {
  font-size: 15px !important;
}
.btn_width_auto {
  width: auto !important;
}
.table_dropdown_menu a.link_text_gren,
.table_dropdown_menu a.link_text_gren a {
  color: #1e24b9 !important;
  cursor: pointer;
}
.table_dropdown_menu a.link_text_gren:hover,
.table_dropdown_menu a.link_text_gren a:hover {
  /* color: #317fe6 !important; */
  color: #f54000 !important;
  cursor: pointer;
}
.light_theme .sidebar_ul .lis_paren_side:hover span {
  color: #fff !important;
}
/* .light_theme .sidebar_ul li.li_bot_less:hover img
{
filter: unset;
} */
.light_theme .lis_paren_side:hover img {
  filter: unset;
}

.light_theme .liquid_raised:hover,
.light_theme .card_bg:hover {
  /* box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  box-shadow: 0px 1px 16px -1px #b6d4a8 !important;
  background-color: #fff !important;
}

/* .launchpad_parent .lis_paren_side_accordion:hover .icon_text_div
{

} */
#launchpad_parent.active,
#privatesale_parent.active,
#lock_parent.active,
#airdrop_parent.active {
  /* background: linear-gradient(180deg, #f54200 0%, #f5ae00 100% ); */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #fff;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
/* #launchpad_parent.active img,#privatesale_parent.active img,
#lock_parent.active img,#airdrop_parent.active img

{
filter: brightness(0) invert(0);
} */

#launchpad_parent.active span,
#privatesale_parent.active span,
#lock_parent.active span,
#airdrop_parent.active span {
  color: #1e24b9;
}

.light_theme #launchpad_parent.active,
.light_theme #privatesale_parent.active,
.light_theme #lock_parent.active {
  /* background: linear-gradient(275deg, #6300DD 0%, rgba(49, 6, 103, 0.72) 100%); */
  /* background: transparent linear-gradient(102deg, #F54200 30%, #F5AE00 77%) 0% 0% no-repeat padding-box; */
  color: #1e24b9;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
@media only screen and (max-width: 991px) {
  .sidebar_shr .right_side_sec {
    width: 100% !important;
  }
  #sidebar {
    background-color: #03060a !important;
    background-image: url(../images/bg.png);
    background-size: cover;
  }
  .light_theme #footer_side {
    background-color: #fff !important;
  }
  .light_theme #footer_side img {
    filter: brightness(0) invert(0);
  }
  .light_theme .btn_bar_mobl {
    color: #fff !important;
  }
  #footer_side {
    background-color: #03060a !important;
    background-image: url(../images/bg.png);
    background-size: cover;
    position: fixed;
    bottom: 0px !important;
    width: 100% !important;
    z-index: 9999999 !important;
    padding: 10px 20px !important;
    border-top: 2px solid #1e24b9;
  }
  .footer_theme_div {
    bottom: 60px !important;
  }
  #sidebar .sidebar_ul {
    max-height: calc(100vh - 125px) !important;
  }
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 70px !important;
  }
  /* .icon_theme_div
  {
    margin-left: 15px;
  } */
  .footer_theme_div {
    left: unset !important;
  }
  #launchpad_parent.active,
  #privatesale_parent.active,
  #lock_parent.active,
  #airdrop_parent.active {
    /* min-height: 30px;
    max-width: 30px !important;
    min-width: 30px !important; */
    display: flex;
    align-items: center;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active {
    min-width: 150px !important;
    min-height: 30px !important;
  }
  .light_theme #sidebar.side_shrink .icon_text_div#launchpad_parent.active span,
  .light_theme
    #sidebar.side_shrink
    .icon_text_div#privatesale_parent.active
    span,
  .light_theme #sidebar.side_shrink .icon_text_div#lock_parent.active span {
    color: #fff !important;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active img {
    filter: brightness(0) invert(0) !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar {
    border: none !important;
  }
  .headerleft {
    margin-left: 50px;
  }
  .shrink_side_btn {
    left: 10px !important;
    top: 30px !important;
    z-index: 99 !important;
  }
  #footer_side {
    display: none;
  }
  .side_shrink .icon_theme_div {
    padding-left: 5px;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active {
    min-height: 30px;
    max-width: 30px !important;
    min-width: 30px !important;
    display: flex;
    align-items: center;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active span,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active span,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active span {
    color: #fff !important;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active img {
    /* filter: unset !important; */
    filter: brightness(0) invert(0) !important;
  }
}

.light_theme #sidebar.side_shrink .inner_card_body_acc {
  background-color: #fff !important;
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
}

.link_wallet_new {
  color: #fff !important;
}
.wallet_address_text {
  color: #fff;
}
.wallet_address_text span {
  color: #1e24b9 !important;
  margin-left: 5px;
}
.wallet_address_text .fa {
  color: #1e24b9 !important;
  margin-left: 5px;
  padding: 5px;
}
.wallet_address_text .fa:hover {
  color: #be2200 !important;
}
.text_green {
  color: #1e24b9 !important;
}
.pool_detail_title {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  overflow-y: auto !important;
}
.table th {
  vertical-align: middle !important;
}
.table_repso_with_dropdwon {
  min-height: 250px !important;
}
.input-groups .input-group.input_grp_profile_url .input-group-text {
  background-color: #1e24b9 !important;
  border: 1px solid #1e24b9 !important;
  color: #fff !important;
  font-size: 12px !important;
}
.input-groups .input-group.input_grp_profile_url .input-group-append {
  padding: 10px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.input-groups .input-group.input_grp_profile_url .input-group-append .fa:hover {
  color: #16f9b2 !important;
}
.bnt_icon_new {
  max-width: 32px !important;
  min-width: 32px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.light_theme .wallet_address_text,
.light_theme .pool_detail_title {
  color: #fff !important;
}
.light_theme .wallet_address_text span,
.light_theme .text_green {
  color: #882af0 !important;
  font-size: 12px !important;
}
.light_theme
  .input-groups
  .input-group.input_grp_profile_url
  .input-group-text {
  background-color: #f1defb !important;
  color: #848484 !important;
  border: unset !important;
}
.btn4_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip_parent {
  position: relative;
  display: inline;
}
.tooltip_custom {
  background: #4f4a4a;
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  left: 20px;
  width: 100px;
  text-align: center;
}
.light_theme .tooltip_custom {
  background: #e1dddd;
  color: #848484 !important;
}
.btn_timer_new span {
  background-color: #1e24b9;
  background: #1e24b9;
  /* background-image: linear-gradient(102deg, #F54200 30%, #F5AE00 77%); */
  color: #fff;
  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 0px;
  min-width: 38px !important;
  min-height: 38px !important;
  max-width: 38px !important;
  max-height: 38px !important;
  display: inline-block;
}
.modal-content .card_bg {
  border-radius: 30px !important;
  background: transparent !important;
}
.light_theme .modal-content .card_bg:hover {
  box-shadow: 0px 0px 0px 0px #02020299 !important;
}
.link_blue_new {
  color: #f56f27 !important;
}

.link_blue_new:hover {
  color: #1e24b9 !important;
}

.load_connect_text {
  position: absolute;
  top: 49%;
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
}
.btn_connect_loader {
  background: #fff !important;
  border: none !important;
  box-shadow: rgb(149 149 187 / 40%) 0px 0px 0px 0px inset !important;
  min-width: 240px !important;
  font-size: 14px !important;
  color: #1e24b9 !important;
}
.btn_connect_loader:hover {
  background: transparent !important;
  border: none !important;
  box-shadow: rgb(149 149 187 / 40%) 0px 0px 0px 0px inset !important;
  color: #f54200 !important;
}
.light_theme .get-started-btn.btn_connect_loader {
  background: none !important;
  color: #fff !important;
}
.light_theme .btn_connect_loader {
  background: none !important;
  color: #6604d3 !important;
}
.table_dropdown_menu .dropdown-item:focus,
.table_dropdown_menu .dropdown-item:hover {
  background-color: #e9ecef1c !important;
}
.table_dropdown_menu.filter_menu.dropdown-menu {
  max-height: 150px !important;
  overflow-y: auto !important;
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  margin: 0px !important;
  transform: translate(0px, 23px) !important;
  display: block !important;
  z-index: 10000 !important;
  opacity: 1 !important;
  pointer-events: initial !important;
}
.table_dropdown_menu.filter_menu.dropdown-menu.d-none {
  display: none !important;
  z-index: 0 !important;
  opacity: 0 !important;
}

.countdown_grey_cen.countdown div {
  font-size: 12px !important;
}
.tokenlistrow:nth-child(2n + 1) {
  background: #222121;
}
.tokenlists h6,
.tokenlists p {
  min-width: 150px;
}
.tokenlistrow {
  width: 550px;
}
.tokenlists h6:last-child,
.tokenlists p:last-child {
  min-width: 100px;
}
.modal .scrolllist {
  height: 400px;
}
.scrolllist {
  overflow-y: auto;
  height: 180px;
  overflow-x: hidden;
}
.tokenlists,
.tokenlists h6 {
  color: #d6d6d6 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

/* NEW CSS */
.admn_form .input-groups .input-group .input-group-text {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.noearp_input_admin .input-group {
  flex-wrap: nowrap !important;
}
.lis_paren_side_admn .lis_paren_side {
  max-width: 180px;
}
/* END NEW CSS */

#loader_div_connect {
  background: #04070b;
  /* / width: calc(100% - 250px); / */
  height: 100%;
  opacity: 0.9;
  position: absolute;
  z-index: 9;
  top: 67px;
  /* / left:20px; / */
}

.light_theme #loader_div_connect {
  background: #e3dede !important;
}
@media only screen and (min-width: 992px) {
  #loader_div_connect {
    background: #04070b;
    width: calc(100% - 250px);
  }
  .load_connect_text {
    position: fixed;
    top: 50%;
    left: calc(50% + 125px);
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 1;
  }
  .sidebar_shr #loader_div_connect {
    width: calc(100% - 58px);
    /* / left: calc(50% + 15px); / */
  }
  .sidebar_shr .load_connect_text {
    left: calc(50% + 15px);
  }
}

@media only screen and (max-width: 991px) {
  #sidebar {
    border-right: 0px !important;
  }
  #loader_div_connect {
    background: #0a071e;
    width: calc(100% - 0px);
  }
  .load_connect_text {
    position: fixed;
    top: 50%;
    left: calc(50% - 0px);
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 1;
  }
}

#sidebar {
  z-index: 10 !important;
  /* position: fixed; */
  position: absolute;

  right: 0;
}

/* NEW CSS */
.admn_form .input-groups .input-group .input-group-text {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.noearp_input_admin .input-group {
  flex-wrap: nowrap !important;
}
.lis_paren_side_admn .lis_paren_side {
  max-width: 200px;
}
/* END NEW CSS */

.table_admin_laun td:nth-child(5),
.table_admin_laun th:nth-child(5) {
  min-width: 160px !important;
}
.table_admin_laun td:nth-child(6),
.table_admin_laun th:nth-child(6) {
  min-width: 130px !important;
}
.table_admin_laun td:nth-child(7),
.table_admin_laun th:nth-child(7) {
  min-width: 160px !important;
}

.table_poricat_list td:nth-child(7),
.table_poricat_list th:nth-child(7) {
  min-width: 130px !important;
}
.table_poricat_list td:nth-child(8),
.table_poricat_list th:nth-child(8) {
  min-width: 100px !important;
}

.table_poricat_list td:nth-child(9),
.table_poricat_list th:nth-child(9) {
  min-width: 100px !important;
}

.table_poricat_list td:nth-child(3),
.table_poricat_list th:nth-child(3) {
  min-width: 130px !important;
}
.table_poricat_list td:nth-child(4),
.table_poricat_list th:nth-child(4) {
  min-width: 160px !important;
}
.link_brk_word {
  word-break: break-all !important;
}

.themeinputgroup {
  display: flex;
}
.themeinputgroup .datepicker_input.input-group {
  border-radius: 0px 0 0 0px !important;
  padding-left: 10px;
}
.themeinputgroup .cur_pointer.input-group-append button {
  border-radius: 0 0px 0px 0;
}
ul.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
}
li.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled:hover {
  color: gray !important;
}
li.react-datepicker__time-list-item:hover {
  color: #fff;
}

.light_theme button[disabled]:hover {
  color: #9b9b9b !important;
}
.light_theme button[disabled] {
  opacity: 0.5 !important;
}
.inut_grp_date_inpt_pad input {
  padding-left: 10px !important;
}

.light_theme #sidebar .accordion .card button {
  color: #fff !important;
}
.light_theme .btn_white_outline_round {
  border-color: #fff !important;
  border: 1px solid #fff !important;
}

.banner_subtitle.form_subhead_1 {
  color: #fff;
  font-size: 16px !important;
  font-weight: 500;
}
.flex_row_cen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.a_brk_new {
  word-break: break-all;
}

.private_sal_size_new {
  min-width: 150px;
}
.btn_social_new_a_new {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.card_bg .card-title {
  /* color: #1E24B9; */
  color: #fff;
}
.tab_img .card_bg {
  /* box-shadow: 0px 1px 9px -2px #1E24B9 !important; */
  background-color: #0a071e !important;
  /* box-shadow: 0px 0px 6px #1E24B9; */
}
.create_lock_date {
  z-index: 2;
}
.light_theme .get-started-btn {
  /* background: #ede5f9 !important; */
  background: transparent linear-gradient(289deg, #1e24b9 0%, #1e24b9 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff !important;
  font-weight: 600;
}

.create_modal .tab_img .card_bg {
  box-shadow: none !important;
}
.light_theme .right_side_sec {
  background: #f7f7f7 !important;
}
.light_theme .card_bg .card_img_pare_div {
  /* box-shadow: none  !important; */
  box-shadow: 0px 0px 6px #1e24b9 !important;
}
.light_theme .card_bg:hover .card_img_pare_div {
  box-shadow: 0px 0px 6px #1e24b9 !important;
  background: #fff !important;
}
.light_theme .tab_img .card_bg {
  /* box-shadow:none !important; */
  box-shadow: 0px 1px 6px -1px #1e24b9eb !important;
  background-color: #fff !important;
}
.login_screen .left_icon {
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 0px !important;
}
.login_screen .right_icon {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0px !important;
}
.login_screen .center_icon {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.light_theme .priv_details .card_bg:hover {
  box-shadow: 0px 1px 16px -1px #6903d91f !important;
  background: #f0dffd !important;
}
.light_theme .badge-green-rect {
  /* background: #660acc; */
  background: #1e24b9;
  color: #fff !important;
}
.light_theme .topBar {
  /* background: #ede5f9 !important  ; */
  background: transparent !important;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}
.light_theme .dropdown_normal_menu a {
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500;
}

.light_theme .create_modal .tab_img .card_bg {
  box-shadow: none !important;
}
.suite_container {
  position: relative;
}
.suite_container .bg_style2 {
  position: absolute;
  right: 0px;
  top: -25px;
}
.suite_container .bg_style2 img {
  width: 100px;
}
.suite_container .bg_style {
  position: absolute;
  left: -25px;
  top: 25px;
}
.suite_container .bg_style img {
  width: 100px;
}
.suite_container .bg_outline {
  position: absolute;
  right: -50px;
  top: 15%;
}
.suite_container .bg_outline img {
  width: 350px;
}
.grow_sec {
  position: relative;
}
.grow_sec .bg_outline2 {
  position: absolute;
  left: -70px;
  top: 20px;
}
.grow_sec .bg_outline2 img {
  width: 250px;
}
.header_bg {
  position: relative;
}
.header_bg .bgstyle_4 {
  position: absolute;
  left: 60%;
  top: 0px;
}
.header_bg .bgstyle {
  position: absolute;
  left: 30%;
  top: -15px;
}
.take_section {
  position: relative;
}
.take_section .bg_outline1 {
  position: absolute;
  /* right: -50px; */
  right: -50px;

  top: -38px;
}

.coming_sec {
  position: relative;
}

.coming_sec .bg_outline2 {
  position: absolute;
  bottom: 10px;
  left: -55px;
}
.coming_sec .bg_outline2 img {
  width: 250px;
}
.coming_sec .bg_outline1 {
  position: absolute;
  top: 10px;
  right: -55px;
}
.coming_sec .bg_outline1 img {
  width: 250px;
}
.coming_sec .coming_soon {
  position: relative;
}
.coming_sec .coming_soon .bg_style {
  position: absolute;
  left: 30%;
}
.coming_sec .coming_soon .bg_style2 {
  position: absolute;
  left: 70%;
  top: 20%;
}
.coming_sec .coming_soon .bg_style img {
  width: 80px;
}
.coming_sec .coming_soon .bg_style2 img {
  width: 80px;
  filter: invert(1);
}
.take_section .bg_outline1 img {
  width: 300px;
}
.top_heqad_sec.private_sec_bg {
  position: relative;
}
.top_heqad_sec.private_sec_bg .bg_outline1 {
  position: absolute;
  right: -58px;
  top: 20px;
}
.top_heqad_sec.private_sec_bg .bg_outline1 img {
  width: 250px;
}

.disclaimer_sec .bg_outline2 img {
  width: 250px;
}
.kyc_sec {
  position: relative;
}
.kyc_sec .bg_outline1 {
  position: absolute;
  right: 5px;
}
.kyc_sec .bg_outline1 img {
  width: 250px;
}
@media only screen and (max-width: 991px) {
  .take_section .bg_outline1 img {
    width: 150px;
  }
  .take_section .bg_outline1 {
    right: -20px;
    top: 20px;
  }
  .suite_container .bg_outline img {
    width: 250px;
  }
  .suite_container .bg_outline {
    right: -20px;
  }
  .suite_container .bg_style2 img {
    width: 55px;
  }
  .suite_container .bg_style2 {
    top: -50px;
  }
  .suite_container .bg_style img {
    width: 70px;
  }

  .coming_sec .bg_outline1 img {
    width: 150px;
  }
  .coming_sec .bg_outline1 {
    right: -20px;
  }
  .coming_sec .bg_outline2 img {
    width: 150px;
  }
  .top_heqad_sec.private_sec_bg .bg_outline1 img {
    width: 150px;
  }
  .top_heqad_sec.private_sec_bg .bg_outline1 {
    position: absolute;
    right: -20px;
    top: -10px;
  }

  .kyc_sec .bg_outline1 {
    display: none;
  }
}

.light_theme .card_bg .card-title {
  color: #130028;
}
.light_theme .get-started-btn:hover {
  background: transparent !important;
  color: #1e24b9 !important;
  border: 1px solid #1e24b9;
}
.out_light {
  display: none;
}
.light_theme .out_dark {
  display: none;
}
.light_theme .out_light {
  display: block;
}
.light_theme #sidebar {
  box-shadow: none;
}
.light_theme .rounded_btn_wal.get-started-btn {
  /* background: #f0dffd !important; */
  background: transparent linear-gradient(102deg, #f54200 30%, #f5ae00 77%) 0%
    0% no-repeat padding-box !important;
}

.contain_iomg_mid {
  position: relative;
}
.middile_dark,
.middile_light {
  position: absolute;
  top: 50px;
  left: 50%;
  object-fit: contain;
  width: 400px;
  transform: translate(-50%, 0%);
}

.middile_light {
  display: none;
}

.light_theme .middile_light {
  display: block;
}

.light_theme .middile_dark {
  display: none;
}
.grp_input_style .input-group-text {
  background: #04030e;
  border: none;
  margin-right: -5px;
}
.grp_input_style .input-group-text i {
  color: #87cf52;
}
.grp_input_style textarea {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background: #04030e 0% 0% no-repeat padding-box;
  border: 1px solid #04030e !important;
}
.grp_input_style textarea:focus {
  background: transparent;
}
.add_airdrops_user .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* NEW CSS */
@media only screen and (max-width: 991px) {
  .lis_paren_side:hover,
  .parent_1_grad.active .lis_paren_side,
  #launchpad_parent.active,
  #privatesale_parent.active,
  #lock_parent.active,
  #airdrop_parent.active {
    /* border-radius: 50% !important;
    min-width: 35px !important;
    min-height: 35px !important; */
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink #launchpad_parent.active,
  .side_shrink #privatesale_parent.active,
  .side_shrink #lock_parent.active,
  .side_shrink #airdrop_parent.active {
    border-radius: 0px 10px 10px 0px !important;
  }
  .side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side {
    justify-content: flex-start !important;
  }

  .side_shrink .lis_paren_side_accordion:hover .icon_text_div {
    padding-left: 10px !important;
  }
  .lis_paren_side_accordion:hover .icon_text_div {
    /* border-radius: 50% !important;
  min-width: 35px;
  min-height: 35px !important; */
    display: inline-flex;
    align-items: center;
    justify-content: flex-start !important;
    /* padding-left: 0px !important; */
  }
  /* #launchpad_parent.active.pl-2,
#lock_parent.active.pl-2,
#privatesale_parent.active.pl-2,
#airdrop_parent.active.pl-2
{
  padding-left: 0px !important;
} */
  .parent_1_grad.active .lis_paren_side {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  /* #sidebar
{
  right: 0px !important;
  left:unset !important;
} */
  .right_side_sec {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  #sidebar .shrink_side_btn {
    right: 40px !important;
    left: unset !important;
  }
  /* #sidebar .footer_theme_div
{
  left:255px !important;
} */
  #sidebar.side_shrink .shrink_side_btn {
    right: 235px !important;
    left: unset !important;
  }
  #sidebar.side_shrink .footer_theme_div {
    right: unset !important;
    /* left:90px !important; */
    padding-left: 20px !important;
    left: unset !important;
  }
  #sidebar .inner_card_body_acc {
    right: 50px !important;
    left: unset !important;
  }
  .lis_paren_side,
  .parent_1_grad .lis_paren_side,
  #launchpad_parent,
  #privatesale_parent,
  #lock_parent,
  #airdrop_parent {
    min-width: 200px;
    min-height: 30px;
  }
  #sidebar .lis_paren_side_cry {
    margin-left: 15px !important;
  }
  .side_shrink .sidebar_ul {
    padding-right: 10px !important;
  }

  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink #launchpad_parent.active,
  .side_shrink #privatesale_parent.active,
  .side_shrink #lock_parent.active,
  .side_shrink #airdrop_parent.active {
    padding-left: 10px !important;
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .lis_paren_side:hover,
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink .parent_1_grad.active .lis_paren_side {
    min-width: 35px !important;
    min-height: 35px !important;
    /* max-width: 35px !important;
    max-height: 35px !important; */
    border-radius: 50%;
    /* display: flex;
    align-items: center; */
    /* padding: 0px !important; */
    /* justify-content: center; */
    /* padding-left: 0.3rem !important; */
  }
  .side_shrink .li_bot_less .icon_text_div {
    padding-left: 0.3rem !important;
  }
  .side_shrink .parent_1_grad .lis_paren_side,
  .side_shrink .parent_1_grad .lis_paren_side:hover {
    padding-left: 0.4rem !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion
    .icon_text_div#launchpad_parent,
  #sidebar.side_shrink
    .lis_paren_side_accordion
    .icon_text_div#privatesale_parent,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div#lock_parent,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div#airdrop_parent,
  #sidebar.side_shrink .icon_text_div#launchpad_parent,
  #sidebar.side_shrink .icon_text_div#privatesale_parent,
  #sidebar.side_shrink .icon_text_div#lock_parent,
  #sidebar.side_shrink .icon_text_div#airdrop_parent,
  #sidebar.side_shrink .lis_paren_side,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div,
  .side_shrink .parent_1_grad .lis_paren_side {
    min-width: 35px !important;
    min-height: 35px !important;
  }
}
/* #header, #header1, #sidebar
{
  border-right: 1px solid #9f9b9bad !important;
} */

.right_side_sec {
  border-left: 1px solid #9f9b9bad !important;
}
.shrink_side_btn:hover {
  background: #fff !important;
}

.light_theme .shrink_side_btn:hover {
  background: #fff !important;
}

.date_inoput_grps {
  background: transparent !important;
  border-radius: 0px !important;
}

.light_theme .sidebar_ul .active span {
  color: #fff !important;
}
.light_theme .sidebar_ul .active img {
  filter: brightness(0) invert(0) !important;
}
.shrink_side_btn .fa-chevron-right::before {
  content: "\f053" !important;
}
.side_shrink .shrink_side_btn .fa-chevron-right::before {
  content: "\f054" !important;
}

.sidebar_shr .trendss {
  padding-left: 30px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #040303 !important;
}

.react-datepicker {
  background-color: #030608 !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  color: #fff !important;
}
.light_theme .react-datepicker {
  background-color: #fff !important;
}
.light_theme .react-datepicker__day-name,
.light_theme .react-datepicker__day,
.light_theme .react-datepicker__time-name,
.light_theme .react-datepicker__current-month,
.light_theme .react-datepicker-time__header,
.light_theme .react-datepicker-year-header,
.light_theme
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  color: #fff !important;
}
.react-datepicker__header,
.react-datepicker__time-container .react-datepicker__time {
  background-color: #030608 !important;
}
.light_theme .react-datepicker__header,
.light_theme .react-datepicker__time-container .react-datepicker__time {
  background-color: #f7f7f7 !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #777 !important;
}

.light_theme .react-datepicker__day--disabled,
.light_theme .react-datepicker__month-text--disabled,
.light_theme .react-datepicker__quarter-text--disabled,
.light_theme .react-datepicker__year-text--disabled {
  color: #ccc !important;
}

.text_area_norla_colr.text_are_grp textarea {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background: #04030e 0% 0% no-repeat padding-box;
  border: none !important;
}

.text_area_norla_colr.text_are_grp textarea.blktextarea {
  background: transparent !important;
}

@media (max-width: 991px) {
  #sidebar {
    z-index: 9999999 !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar {
    right: unset !important;
    left: 0px !important;
  }
}

/* NEW HOME CSS */
.banner_left_ttl {
  font-size: 40px;
  color: #5eef16;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.banner_left_hint {
  color: #cbcbcb;
  font-size: 13px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.mainlogo_img {
  height: 50px;
  width: 180px;
}
.banner_left_dtls {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
.banner_skyblueblur {
  position: absolute;
  top: -15px;
}
.banner_yellowblur {
  position: absolute;
  bottom: -90px;
  left: -270px;
  height: 400px;
  width: 400px;
}

.relative_bitdealts {
  position: relative !important;
  /* overflow: hidden; */
}
.bitdealz_yellowblur {
  position: absolute;
  bottom: -90px;
  right: -250px;
}
.features_yellowblur {
  position: absolute;
  top: -145px;
  left: -270px;
  height: 400px;
  width: 400px;
}
.bitdealts_yellowblur {
  display: none;
  position: absolute;
  top: 50px;
  left: 25%;
  height: 200px;
  width: 200px;
}
.second_yellowblur {
  position: absolute;
  right: -220px;
  height: 300px;
  width: 300px;
}
.why_bitdeals_txt {
  color: #cbcbcb;
  font-weight: 600;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}
.bitdeals_why {
  color: #1e24b9;
  font-family: "Poppins", sans-serif;
}
.thunder_boldtxt {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0px;
}
.thunder_whole {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.thunder_whole:hover {
  outline: 2px solid #56a130;
  cursor: pointer;
}
.green_hrline {
  border-bottom: 1px solid #56a130;
}
.bottom_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative !important;
}
.footer_yellowblur {
  position: absolute;
  height: 250px;
  right: -194px;
  width: 200px;
}
.social_icons_bottomfoot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -7px;
}
.footer_social_logos {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}
.footer_social_logos:hover,
.light_theme .footer_social_logos:hover {
  color: #56a130;
  cursor: pointer;
}
.copyright_txt.banner_left_hint {
  font-weight: 600 !important;
  margin-left: 50px !important;
}
.light_theme .banner_left_hint,
.light_theme .why_bitdeals_txt,
.light_theme .thunder_boldtxt,
.light_theme .footer_social_logos {
  color: #fff;
}
.landing_banner {
  overflow-x: hidden;
}
.light_theme .landing_banner {
  background-image: url("../images/bitdeals/whiterain.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.dark_theme .landing_banner {
  background-image: url("../images/bitdeals/landingbanner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.girl_rocketimg {
  margin-top: -50px;
}
@media (992px < width < 1200px) {
  .girl_rocketimg {
    margin-top: 0px;
  }
}
@media (width < 992px) {
  .copyright_txt.banner_left_hint {
    margin: 0 !important;
  }
}
@media (width < 768px) {
  .banner_toprow {
    margin-top: 30px;
  }
  .row.reverse_row {
    flex-direction: column-reverse;
  }
}

@media (768px < width < 992px) {
  .banner_left_ttl {
    font-size: 30px;
  }
  .thunder_whole {
    padding: 10px;
  }
  .row.reverse_row {
    align-items: center;
  }
}
@media (width < 576px) {
  .bottom_footer {
    display: unset;
  }
  .copyright_txt.banner_left_hint {
    text-align: center;
  }
  .social_icons_bottomfoot {
    justify-content: center;
    margin-top: 20px;
  }
}
@media (width < 450px) {
  .mainlogo_img {
    height: 25px;
    width: 115px;
  }
  .banner_left_ttl {
    font-size: 30px;
  }
  .copyright_txt.banner_left_hint {
    font-size: 12px;
  }
  .why_bitdeals_txt {
    font-size: 20px;
  }
}

.light_theme .header_top .get-started-btn {
  font-weight: 400 !important;
}
.banner_toprow .get-started-btn,
.banner_toprow .banner_left_hint,
.banner_toprow .banner_left_ttl {
  z-index: 1 !important;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .overlay_card_head.font_30 {
    font-size: 20px !important;
  }
  .banner_yellowblur {
    bottom: -190px !important;
  }
}
.kyc_status_div_final {
  text-align: center;
}
.kyc_fianl_text1 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Orbitron", sans-serif;
}
.kyc_fianl_text2 {
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px !important;
  font-family: "Orbitron", sans-serif;
}
.kyc_status_div_final {
  padding: 100px 10px;
}
.row_height_kyc {
  min-height: calc(100vh - 200px);
}

.header_flex.header_flex_new_ad {
  flex-direction: row !important;
}

.img_walt_hader {
  max-width: 13px !important;
}
.get-started-btn:hover .img_walt_hader {
  filter: brightness(0) invert(1);
}
.light_theme .get-started-btn:hover .img_walt_hader {
  filter: brightness(0) invert(0);
}
/* .lis_paren_side:hover img.icon_sidebar
{
filter: brightness(0) invert(0);
} */
.lis_paren_side:hover span {
  color: #007bff !important;
}
.trending_red {
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
}
.btn_text_coin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_text_coin img {
  max-width: 18px !important;
  margin-right: 10px !important;
}

.btn_trend_ad {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 0px !important;
}
.ing_char_robot {
  position: absolute;
  top: -59px;
  object-fit: cover;
  right: 15px;
  height: calc(100% + 95px) !important;
}
.flex_text_div_home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_sec_image {
  background: #0a071e 0% 0% no-repeat padding-box;
  border: 1px solid #201d26;
  box-shadow: 1px 1px 0px #42414f;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_img_head {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.card_img_subhead {
  color: #d3cece;
  font-size: 14px;
}
.card_sec_image img {
  max-width: 50px;
  min-height: 48px;
  max-height: 48px;
  object-fit: contain;
}
.heading_card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: 16px;
  font-weight: 700 !important;
}
.card_home_style_2 {
  background: #0a071e !important;
  border: 1px solid #201d26 !important;
  border-radius: 15px !important;
  cursor: pointer;
  padding: 20px 15px 20px 20px;
  box-shadow: 1px 1px 0px #42414f;
}
.heading_pool_head {
  color: #fff;
  font-size: 14px;
}
.heading_pool_subhead {
  color: #d3cece;
  font-size: 12px !important;
}
.heading_pools {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headeing_tet_flex {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.badge_green_token {
  background: transparent linear-gradient(102deg, #f54200 30%, #f5ae00 77%) 0%
    0% no-repeat padding-box;
  color: #fff;
  border-radius: 0px;
  padding: 5px 15px;
}
.card_bg_robot {
  position: relative;
}
.card_bg_robot {
  background-image: url(../images/bg_robot.png) !important;
  background-position: right 50% top 50% !important;
  background-size: 169px 169px !important;
  background-repeat: no-repeat !important;
}

.card_bg_robot_sal.card_bg_robot {
  background-size: 90px 90px !important;
}
.token_img_bg {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.text_green_partners {
  color: #1e24b9;
  font-size: 16px;
  font-weight: 700;
}
.img_content_scroll {
  display: block;
  white-space: nowrap;
  max-width: calc(100% - 108px);
  overflow-x: auto;
  margin-left: 30px;
}
.img_content_scroll img {
  margin: 0px 10px;
}
.img_flex {
  display: flex;
  align-items: center;
}
.light_theme .modal_body .liquid_raised {
  background-color: #f1eded !important;
}

.tab_img .card_bg {
  background-image: url(../images/bg_robot.png) !important;
  background-position: right 10% bottom 10% !important;
  background-size: 169px 169px !important;
  background-repeat: no-repeat !important;
}

.shrink_side_btn,
.light_theme .shrink_side_btn.rounded_btn_wal.get-started-btn {
  background: transparent !important;
  border-radius: 0px !important;
  border: 0px !important;
  box-shadow: rgb(149 149 187 / 40%) 0px 0px 0px 0px inset !important;
}
.light_theme .shrink_side_btn img {
  filter: brightness(0);
}

#footer_side .img_bar {
  max-width: 15px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #6c6969 !important;
}

.light_theme
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #fff !important;
}

.datepicker_input.input-group,
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__triangle {
  left: 0% !important;
}

@media only screen and (min-width: 992px) {
  .react-datepicker__triangle {
    left: -50% !important;
  }
}

@media only screen and (max-width: 422px) {
  .react-datepicker__time-container,
  .react-datepicker__month-container {
    width: 100% !important;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 0% !important;
  }
}

@media only screen and (max-width: 450px) {
  .div_btn_ful_width .get-started-btn,
  .div_btn_ful_width .get-started-btn-border {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 20px;
  }
  .btn_min_wid {
    min-width: 90px !important;
    max-width: 90px !important;
  }
}
@media only screen and (min-width: 423px) {
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 100px !important;
  }
}

.dropdown_normal_menu a p {
  margin-bottom: 0px !important;
}

.nav_green_scroll_tab {
  display: inline-block !important;
  white-space: nowrap;
  max-width: 100% !important;
  overflow-x: auto;
  min-width: 100% !important;
  padding-bottom: 10px;
  padding-top: 10px;
}
.nav_green_scroll_tab .nav-item,
.nav_green_scroll_tab .nav-item a {
  display: inline-block !important;
}
.img_overlp_div {
  max-width: 50px;
  min-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  position: relative;
}
.img_overlp_div .img_user {
  max-width: 50px;
  min-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.overlap_icon {
  position: absolute;
  bottom: 0px;
  right: -5px;
  max-width: 20px !important;
  max-height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  border-radius: 50%;
}
.td_leader_bold {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.td_leader_sm {
  font-size: 13px;
  color: #d6d6d6;
}
.td_leader_theme {
  color: #1e24b9;
  font-size: 14px;
}
.text_img_td {
  display: flex;
  align-items: center;
}
.text-right .text_img_td {
  justify-content: flex-end;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #1e24b9 !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #1e24b9 !important;
}
.table_leadrlist tr,
.table_leadrlist tr:first-child {
  background: #04030e 0% 0% no-repeat padding-box !important;
  border-block-end: 4px solid #fff0;
}

.light_theme .table_leadrlist tr,
.light_theme .table_leadrlist tr:first-child {
  background-color: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-block-end: 3px solid #f5faff !important;
}

.table_leadrlist td:nth-child(2) {
  min-width: 300px !important;
}

.table_leadrlist td:last-child {
  min-width: 200px !important;
}

.light_theme .td_leader_bold {
  color: #fff;
}
.light_theme .td_leader_sm {
  color: #837c7c;
}

.text_are_grp.text_area_norla_colr.resize_appra textarea {
  resize: vertical !important;
  padding: 10px !important;
}

.text_are_grp.text_area_norla_colr.resize_appra.resize_appra_none textarea {
  resize: none !important;
}

.card_bg_wizard_row_he .card_bg_wizard {
  min-height: 130px;
}
.rom_min_h_disclmr {
  min-height: calc(100vh - 205px);
}
.nav_green_scroll_tab .nav-link.active {
  background: transparent !important;
}
.nav_green_scroll_tab .nav-link.active.active_el,
.nav_green_scroll_tab .nav-link.active:hover {
  background: #1e24b9 0% 0% no-repeat padding-box !important;
}
/* kr css */
.upload_btn_alignkr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
}

/* kr media query */
@media (width < 450px) {
  .upload_btn_alignkr {
    display: unset;
    text-align: center;
  }
  .upload_krbtn {
    margin-top: 20px;
  }
}
/* end of kr media query */
/* end of kr css */

.light_theme .nav_green_scroll_tab .nav-link.active {
  background: transparent !important;
}
.light_theme .nav_green_scroll_tab .nav-link.active.active_el {
  background: #1e24b9 0% 0% no-repeat padding-box !important;
}
.view_mail {
  background: #04030e 0% 0% no-repeat padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  border: 1px solid #04030e !important;
  color: #fff !important;
  min-height: 42px !important;
  font-size: 12px !important;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}

.scroll_view_claim {
  max-height: 200px !important;
  overflow-y: auto;
  padding-right: 10px !important;
}

.view_mail_stat {
  color: #fff;
}

.light_theme .inut_div_vi_sc p {
  color: #626161 !important;
}

.recepients_table_claim_v.table tr {
  background-color: transparent !important;
}
.word_brsk_tect_new {
  word-break: break-all;
}

@media only screen and (max-width: 430px) {
  .praiv_btn_new button {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .praiv_btn_new button {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
.word_bradk_token_a {
  word-break: break-all;
}

.link_ui_admin {
  color: #1e24b9 !important;
}
.link_ui_admin:hover {
  color: #f54200 !important;
}
.pasw_frp_inner .left_icon,
.pasw_frp_inner .right_icon {
  background-color: #04030e;
  border: none;
}
.pasw_frp_inner .left_icon {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-radius: 0px !important;
}
.pasw_frp_inner .right_icon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-radius: 0px !important;
}

.card_admin_gorm {
  height: unset !important;
}
.card_bg.blur_acc_card {
  -webkit-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}

.airss a {
  color: #f5ae00 !important;
}
.airss a:hover {
  color: #f5ae00 !important;
}
.kyclist {
  background: transparent !important;
  border: unset;
  width: 70%;
  text-align: left;
  padding: 10px 0px;
  border-radius: 0px 10px 10px 0px;
  max-width: 70%;
  min-width: 70%;
}
.ip_table_size {
  width: 50% !important;
  margin: auto;
}

.text-success,
.card-body .text-success {
  color: #f5ae00 !important;
}

#logo_overlay.overflow_show {
  overflow: auto !important;
}
.login_text {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.whole_sec.login_screen.pb-5 {
  padding-bottom: 3rem !important;
}
.linea_icon {
  max-width: 15px !important;
}
.link_wallet_new:hover .linea_icon,
.header_flex_new_ad .get-started-btn:hover .linea_icon {
  filter: brightness(0) invert(1) !important;
}

.icon_align {
  position: absolute;
  padding: 15px;
  top: -40px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  min-width: 65px;
  background-color: #0b0729;
  border-radius: 50%;
  box-shadow: 0 1px 9px -1px #42414f !important;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
}

.card_img_pare_div img {
  max-width: 40px;
  max-height: 40px;
}
.diable_network_div {
  opacity: 0.5;
  cursor: not-allowed !important;
}

/* NEW CSS */
.card_overlay {
  background-color: rgb(0 0 0 / 60%);
}
.overlay_card_head {
  font-family: "Orbitron", sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.orbitron_font {
  font-family: "Orbitron", sans-serif;
}
.font_30 {
  font-size: 30px !important;
}
.contract {
  width: 125px;
  padding: 1rem;
  height: 45px;
}
.contractbg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
  /* background: #1E24B9; */
  background: #1e24b9;
  z-index: 0;
}
.contract .name {
  font-size: 12px;
  line-height: 1rem;
  font-family: "Orbitron", sans-serif;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-weight: 700;
}
.borderGradient {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  gap: 0.5rem;
  background-color: hsl(0 0% 0% / 0.5);
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: var(--whitetext);
  height: 45px;
  border: 1px solid #1e24b9;
}
.borderGradient input,
.borderGradient input:focus {
  background-color: transparent;
  color: #fff;
  border: none;
  height: 40px;
}

.borderGradient span {
  text-decoration: underline;
  font-weight: 600;
}
.img_linea {
  max-width: 120px !important;
}
.img_linea_mob {
  max-width: 90px !important;
}

.notchedButton {
  outline: none;
  border: none;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 0.25s;
  filter: drop-shadow(0px 4px 38px rgba(0, 217, 245, 0.25));
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 0.875rem;
  background-color: transparent;
  min-width: 160px;
  display: inline-block;
  text-align: center;
  color: #fff;
  transition-duration: 0.25s;
  transition-property: all;
  transition-timing-function: ease;
}
.notchedButton::before {
  top: 4px;
  right: 4px;
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  z-index: 2;
}
.notchedButton .notchedBg {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.2s ease;
  z-index: 0;
  clip-path: polygon(
    0 0,
    0 0,
    calc(100% - 17px) 0,
    100% 17px,
    100% 100%,
    100% 100%,
    17px 100%,
    0 35px
  );
  background: #1e24b9;
}
.notchedButton .content {
  position: relative;
  padding: 0.875rem 0.625rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.notchedButton:hover {
  transform: scale(95%);
}
.videoPlay {
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.videoPlayButton {
  border: none;
  outline: none;
  background: transparent;
  width: 65px;
}
.titleCard {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: hsla(0, 0%, 100%, 0.1);
  width: fit-content;
  letter-spacing: 4.8px;
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: var(--font-orbi);
  color: var(--hsl-white);
}

/* MEDIA CSS */

@media only screen and (min-width: 768px) {
  .video_bg_img {
    position: relative;
    right: 0px;
    height: 250px !important;

    width: 100%;
    min-width: 400px !important;
    object-fit: 100% 100%;
    border-radius: 20px;
    object-position: top;
  }
  .videoPlay {
    transform: translate(-50%, -50%) !important;
  }
}

/* @media only screen and (min-width:1200px)
{
  .video_bg_img
  {
    min-width: 405px !important;
  }
} */

.squareone {
  position: absolute;
  max-width: 75px;
  top: -120px;
  left: -150%;
}

.squaretwo {
  position: absolute;
  max-width: 50px;
  bottom: -75px;
  left: -170%;
}

.card_tab {
  border: 1px solid #1e24b9 !important;
  border-radius: 20px !important;
  background: transparent !important;
}
.card_tab_header {
  border-bottom: 1px solid #1e24b9 !important;
  border-radius: 20px !important;
  color: #fff;
  position: relative;
  font-family: "Orbitron", sans-serif;
  font-size: 13px;
  font-weight: 700;
}
.btn_stop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card_desc_content {
  color: #fff;
  font-size: 14px;
}
.icon_stop {
  max-width: 40px;
}
.card_tab_val {
  padding: 25px 15px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.card_tab_val.val_first {
  border-radius: 20px 0px 0px 20px;
}
.card_tab_val.active {
  color: #fff !important;
}
.card_tab_val.val_second {
  border-radius: 0px 20px 20px 0px;
}
.card_tab_val.active {
  /* background: #1E24B9; */
  background: #1e24b9;
  color: #fff;
}
.card_tab_val.inactive {
  background: transparent !important;
  color: #fff;
}

.bg_layer {
  position: relative;
}
.bg_layer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 50%);
}

.newfooter .footerBg {
  object-fit: cover;
  inset: 0;
  height: 100%;
  width: 100%;
}

/* @media only screen and (min-width:768px) and (max-width:991px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
    
    width: 100%;
    min-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(0%, 100px) !important;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
   
    width: 100%;
    min-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(0%, 100px) !important;
  }
}

@media only screen and (min-width:1200px) and (max-width:1299px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
  
    width: 100%;
    min-width: 400px !important;
    max-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(0%, 100px) !important;
  }
}


@media only screen and (min-width:1300px) and (max-width:1399px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
   
    width: 100%;
    min-width: 400px !important;
    max-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(5%, 100px) !important;
  }
}


@media only screen and (min-width:1400px) and (max-width:1499px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
   
    width: 100%;
    min-width: 400px !important;
    max-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(50%, 100px) !important;
  }
}



@media only screen and (min-width:1500px)
{
  .video_bg_img
  {
    position: absolute;
    right: -75px;
    height: 250px !important;
   
    width: 100%;
    min-width: 400px !important;
    max-width: 400px !important;
  }
  .videoPlay
  {
    transform: translate(100%, 100px) !important;
  }
} */
@media only screen and (min-width: 992px) {
  .borderGradient input {
    min-width: 250px;
  }
  .min_heighttab_cont {
    min-height: calc(100vh - 300px);
  }
}
@media only screen and (max-width: 575px) {
  .row_rever_md {
    flex-direction: column-reverse;
  }
  .topBar {
    background: #fff000bd;
  }
  .video_bg_img {
    min-height: 200px;
    margin-top: 30px;
  }
  .squareone,
  .squaretwo {
    display: none;
  }
  .newfooter {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .row_rever_md {
    flex-direction: column-reverse;
  }
  .topBar {
    background: #fff000bd;
  }
  .video_bg_img {
    min-height: 250px;
    margin-top: 30px;
  }
  /* .squareone,.squaretwo
  {
display: none;
  } */
  .newfooter {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newfooter {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img_linea {
    max-width: 90px !important;
  }
}

.take_section {
  min-height: 250px;
}

.card_bg_grad.card_bg:hover {
  background-color: #1e24b9 !important;
  /* background: #1E24B9 !important; */
  box-shadow: 0px 0px 0px 0px #76ab9e !important;
}
.card_bg_grad.card_bg:hover .card_img_pare_div {
  background: #000 !important;
}

/* footer css */
.newfooter {
}
.newfooter .auditBg {
  opacity: 0.5;
}
.newfooter .container {
  max-width: 98%;
  padding-top: 79px;
  padding-bottom: 79px;
}
.newfooter .right {
  padding-top: 3rem;
  padding-left: 1rem;
  gap: 3rem;
}
.newfooter .right .logo img {
  width: 180px;
}
.newfooter .textOrbiXxl {
  color: #eaedff;
  leading-trim: both;
  text-edge: cap;
  font-family: Orbitron;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 84px; /* 84% */
  letter-spacing: -2px;
  text-transform: uppercase;
  margin: 48px 0px;
}

.newfooter .social {
  justify-content: flex-start;
  gap: 24px;
}
.newfooter .social button {
  background: transparent;
  border: none;
  outline: none;
}
.newfooter .social button img {
  width: 60px;
  height: 60px;
}
.newfooter .left .options {
  gap: 1.5rem;
  padding-top: 3rem;
  padding-right: 1rem;
  position: relative;
  z-index: 999999;
}
.newfooter .left .options button {
  border: none;
  outline: none;
  background: transparent;
  color: #c4cbf5b2;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  width: fit-content;
  text-transform: none;
}
.newfooter .left .options button:hover,
.newfooter .left .copyright .links a:hover {
  color: #1e24b9 !important;
}
.newfooter .left .copyright {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.newfooter .left .copyright .text {
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: var(--font-plus);
  margin-bottom: 34px;
}
.newfooter .left .copyright .links {
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.newfooter .left .copyright .links a {
  color: #c4cbf5b2;
  font-weight: 600;
  font-family: var(--font-plus);
  text-decoration: none;
}

.newfooter .right .footer_links {
  display: flex;
  flex-direction: column;
}

.newfooter .right .footer_links .headline {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}

.newfooter .right .footer_links .footer_links_list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
}
.w-fit {
  width: fit-content;
}

.newfooter .right .footer_links .footer_links_list * {
  color: #fff;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .newfooter .right .footer_links .headline {
    font-size: 20px;
  }

  .newfooter .right .footer_links .footer_links_list * {
    font-size: 14px;
  }

  .newfooter .social button img {
    width: 70px;
    height: 70px;
  }
}

/* media */

@media (min-width: 768px) {
  .newfooter .left .options button {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.newfooter .left .options button {
  color: #c4cbf5;
}
@media (min-width: 992px) {
  .newfooter .left .options {
    gap: 4rem;
    flex-direction: column;
    padding-top: 120px;
    padding-right: 5rem;
  }
  .newfooter .left .options button {
    font-size: 28px;
    line-height: 2rem;
  }
  .newfooter .overlay {
    background: rgb(0 0 0 / 40%);
    /* background: red; */
  }
  .newfooter .overlay .footerCenter {
    top: 73px;
    left: -41px;
  }
}

@media (max-width: 1200px) {
  .newfooter .textOrbiXxl {
    font-size: 3rem;
  }
}
@media (min-width: 1800px) {
  .newfooter .container {
    max-width: 85%;
  }
}

@media (min-width: 1700px) {
  .whole_sec {
    background-size: cover;
    background-attachment: scroll;
  }
}

.custom-radio .custom-control-label::before {
  border-radius: 0px !important;
  width: 10px !important;
  height: 10px !important;
}

.btn_min_wid {
  min-width: 120px;
}
.custom-control-label::before {
  width: 10px !important;
  height: 10px !important;
  border-radius: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}

.modal-body .custm_sel,
.modal-body .input-groups .input-group input,
.card_grad_new .input-groups .input-group textarea {
  border: 1px solid #fff !important;
  color: #fff !important;
  background-image: none !important;
}

.card_grad_new .custm_sel,
.card_grad_new .input-groups .input-group input {
  border: 1px solid #fff !important;
  color: #fff !important;
  background-image: none !important;
}

.modal-body .custm_sel option {
  color: #fff !important;
}

.card_grad_new .custm_sel option {
  color: #fff !important;
}

.modal-body .light_theme .input_desc_sm,
.modal-body .input_desc_sm {
  font-weight: 700 !important;
}

.card_grad_new .light_theme .input_desc_sm,
.card_grad_new .input_desc_sm {
  font-weight: 700 !important;
}

.modal-body .note_desc p,
.modal-body .input_desc_sm,
.modal-body .light_theme .input_desc_sm,
.modal-body .input_desc_sm .text-success,
.modal-body .input-groups .input-group input,
.modal-body .input-groups .input-group input::placeholder,
.modal-body .input-groups .input-group input:focus,
.modal-body .input-groups .input-group input.form-control::placeholder,
.card_grad_new .note_desc p,
.card_grad_new .input_desc_sm,
.card_grad_new .light_theme .input_desc_sm,
.card_grad_new .input_desc_sm .text-success,
.card_grad_new .input-groups .input-group input,
.card_grad_new .input-groups .input-group input::placeholder,
.card_grad_new .input-groups .input-group input:focus,
.card_grad_new .input-groups .input-group input.form-control::placeholder,
.card_grad_new .input-groups .input-group textarea,
.card_grad_new .input-groups .input-group textarea::placeholder,
.card_grad_new .input-groups .input-group textarea:focus,
.card_grad_new .input-groups .input-group textarea.form-control::placeholder {
  color: #fff !important;
}

.modal-body .input-groups .input-group input:focus,
.modal-body .input-groups .input-group textarea:focus,
.card_grad_new .input-groups .input-group input:focus,
.card_grad_new .input-groups .input-group textarea:focus {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

.card_grad_new {
  background: #1e24b9 !important;
  border-radius: 0px !important;
  border: none !important;
}
.overlay_card_head.text-blak {
  color: #fff !important;
  font-weight: 700;
}
.wallet_address_text_bk {
  word-break: break-all;
}

.wallet_chekc .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff000 !important;
  background-color: #fff000 !important;
  background-image: linear-gradient(180deg, #fff000, #fff000);
}
.wallet_chekc .custom-control-label {
  color: #fff !important;
  font-size: 14px !important;
}
.wallet_chekc .custom-control-label::before {
  top: 6px !important;
  border: 1px solid #fff000 !important;
  background-color: #fff !important;
}

.btn_positoned .input-group-append {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
}
.btn_positoned .input-group-append button {
  max-height: 20px !important;
  min-height: 20px !important;
  font-size: 12px;
  font-weight: 600;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 48px;
}
.btn_positoned .input-group-append button:hover {
  background-color: #fff !important;
  background: #fff !important;

  transition: 0.5s;
  color: #1e24b9 !important;
}
.btn_positoned {
  position: relative;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f55b00;
  color: white;
  font-weight: bold;
}
.row_ppos .bg_layer .card-body {
  position: relative;
  z-index: 1;
}

.table_nav .nav-item:last-child .nav_link {
  margin-right: 0px !important;
}

.card_bg_steps .desc_grey_txt {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.card_bg_steps .desc_grey_txt.font_12 {
  font-size: 12px !important;
}
.bottom_border_blk {
  border-bottom: 1px solid #fff !important;
}
.hr_border_blk {
  border-top-color: #fff !important;
  width: calc(100% - 30px) !important;
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}

.lockdetail_table td,
.lockdetail_table th {
  color: #fff !important;
}
.lockdetail_table th {
  font-weight: 700 !important;
}

.lockdetail_table td {
  font-weight: 500 !important;
}
.lockdetail_table th:last-child,
.lockdetail_table td:last-child {
  min-width: 150px !important;
}
.addre_brk_Val {
  word-break: break-all;
}

.dark_theme .card_style_1.card_vip,
.dark_theme .card_style_1.card_vip .card-body {
  background: #1e24b9 !important;
}

.banner_sec {
  min-height: 170px;
  max-height: 170px;
  overflow: hidden;
}
.banner_sec img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-height: 170px;
  -webkit-clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
}
.card_style_clip {
  -webkit-clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%
  );
}
.card_header_new {
  position: relative;
}
.card_header_new {
  max-height: 170px;
  min-height: 170px;
}
.card_header_new .profimg {
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card_vip .get-started-btn {
  border-color: #fff !important;
  color: #fff !important;
}
.card_vip .get-started-btn:hover {
  background: #fff !important;
  color: #1e24b9 !important;
}

.positon-abs-badge {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
  /* max-width: 170px; */
  max-width: 220px;
}
.positon-abs-badge .badge {
  text-transform: uppercase;
  border-radius: 0px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  font-family: "Orbitron", sans-serif;

  background: #1e24b9 !important;
}

.card_vip .positon-abs-badge .badge,
.card_vip .positon-abs-badge .badge .text_in_badge_a {
  background: #fff !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-family: "Orbitron", sans-serif;
  margin-bottom: 4px;
}
.positon-abs-badge .badge,
.positon-abs-badge .badge .text_in_badge_a {
  border-radius: 0px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-family: "Orbitron", sans-serif;
  margin-bottom: 4px;
}

.carousel_banner {
  min-height: 350px;
  max-height: 350px;
  -webkit-clip-path: polygon(100% 0%, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);

  clip-path: polygon(100% 0%, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
  background-color: #fff;
}
.carousel_banner img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  object-position: top 0px left 0px;

  min-height: 350px;
  max-height: 350px;
  /* -webkit-clip-path: polygon(0 20px, 20px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%); */
  /* clip-path: polygon(0 20px, 20px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%); */
}

.carousel_div .badge_live::before,
.carousel_div .badge_upcoming::before,
.carousel_div .badge_finish::before {
  left: 2px !important;
}
.carousel_div .badge_live,
.carousel_div .badge_upcoming,
.carousel_div .badge_finish {
  padding-left: 18px !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #fff !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #1e24b9 !important;
}
.grad_badge .btn_social_new {
  background-image: linear-gradient(180deg, #3c3535, #3c3535) !important;
  border: 1px solid #3c3535 !important;
  color: #fff;

  font-family: "Orbitron", sans-serif;
  cursor: pointer;
}
.grad_badge .btn_social_new:hover {
  background: #1e24b9 !important;
  border: 1px solid #1e24b9 !important;
  color: #fff;
}
.progress_div_pos {
  position: relative;
}

.abs_per_div {
  /* margin-top: -72px; */
  position: absolute;
  color: #fff !important;
  font-size: 9px !important;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -47%);
  margin-bottom: 0px;
}

.tooltip-inner {
  background-color: #fff !important;
  color: #fff !important;
  border-radius: 0px !important;
  font-size: 10px !important;
  font-weight: 500;
}

.tooltip {
  background-color: transparent;
}
.tooltip.show {
  opacity: 1 !important;
}

.tooltip .arrow::before {
  border-top-color: #fff !important;
}
.pos_z_body_car {
  position: relative;
  z-index: 1;
}
.tetx_yellow_no {
  color: #1e24b9 !important;
}
.brk_tetx_vla {
  word-break: break-all;
}
.right_side_sec {
  min-height: calc(100vh);
}

.cur_pointer_lik {
  cursor: pointer;
}
.cur_pointer_lik:hover,
.cur_pointer_lik.text-light:hover {
  color: #1e24b9 !important;
}

.modal-dialog .modal-content .cur_pointer_lik:hover,
.modal-dialog .modal-content .cur_pointer_lik.text-light:hover {
  color: #fff !important;
}

.text-gradient-coming {
  color: #f54200 !important;
  background: -webkit-linear-gradient(90deg, #f54200, #f5af00);
  background: linear-gradient(90deg, #f54200, #f5af00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700 !important;
  font-size: 25px !important;
}
.btn_black.bnt_icon_new {
  padding: 13px 18px !important;
}
.text-black,
.text_blk_lnk {
  color: #fff !important;
}
.text_blk_lnk:hover {
  color: #fff !important;
}

.card_header_pos {
  position: relative;
}
.card_header_detail {
  min-height: 240px;
  max-height: 240px;
  overflow: auto;
}
.card_header_detail img {
  min-height: 240px;
  max-height: 240px;
  overflow: auto;
  object-fit: cover;
  width: 100%;
}
.card_header_pos .profimg {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profimg.profimg_detl {
  width: 80px !important;
  height: 80px !important;
  min-width: 80px !important;
  min-height: 80px !important;
}
.profimg.profimg_detl img {
  width: 80px !important;
  height: 80px !important;
  min-width: 80px !important;
  min-height: 80px !important;
}
.detail_headingname {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-family: "Orbitron", sans-serif;
  padding-top: 50px !important;
}

.badge_grad_new {
  background: #1e24b9;
  color: #fff !important;
  padding: 7px 25px;
  font-size: 12px !important;
  cursor: pointer;
  font-weight: 500;
}
.badge_grad_new a {
  color: #fff !important;
}

.badge_grad_new:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJcAAAAyCAYAAABRTkkkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ7SURBVHgB7Z1N1tUgDIYT5LgN5x6343FrbsH9uA038A0aS/5IuZ8DNcP3Gdzbe0shhBQoBcJvX78IkQitTyLm9YPXtx6J/nf/WgFY7Bw5dmhh7GI/tf7KH6TX+gHtaynjjjAlakt7ySEhj6XwkMPT1ORCbg1gkW4xZF9L/JpWhKxye/oqa4mzBl1nH+dO2Ylf8l/1W/OS+o48rOBHHFX/er2KEl+WSZWJDnGq4j0/cgbKcuGX8PVclSX1rPqMg5SIp7BGxDw4bMwF1eTPa7yQU2jWNC3lSMQFF/9gM5CacS9vj+v+urLwz5xIymB6VN3wvgkkSuwhh8VHaWIpiv8k3v8X6TnK6jCXfaHqZIW59RXG9tQplYLL5GoqRaerIFke+t7G58qocQxTgZ1LadTSS17SOPIGzri0lihpFkMN3TPtm4CeBriNysvAZYsbnzhtTr8nDYvs44+f71oyAH/L27fPl9Zct63JWasD8D+I1+pTBjH9oY0F4F/wrtbdLMK0QDfD+myT0CKCZvzJi824YGCgEW0Wbwu7+1xoFEEzH2z0Q0dOdDgCgCa8Qy/6tIg+Pehm2ZWOc8GyQCsjXv8MdOdBL/EabcZjIwHQBA97wTi0z8Xoc4E+lk3pi2sbkyAA+jCbum1s7GktAHSgE7Hu2gvjXKAfq7BWs4haCzTjFhXjXDAw0IfbFMa5QD/ezcppzgC0sZtFskdHAJqI1hB9LtAP65IgH6EfsC3Qh9dcMmO9IQBtsA3MT33JiEk3oBOfOj8vvPwB3QxrDSd9IAxFgF740hprxn4KBEAT4rth+DgX1vODRnKca/imLwA0Ib6R0bQDtIqgkRFfQzccA6ANVpvSPhdjKAK0YlsCsva50CaCXrTCknj9g7oLNDJsMsQgrP4Bzaz+1nqtOG3PSrSMoJFhu13rOBd6XaCTWAs7BZu/gW6GTW4e/glAKzJsN2dUXKCXYfuijtWrFyzQAJ3oNiTCczthAaCJYc5/sFU4aMc8aKwReky5Ac1wLuf3WYMAdLEeEpd15XL+X98/SbhDC8IBYXpL4z0hOlzA1bDhdjH/o/TJZp7TSvxcplebpz1Pz4+L57ydrs0982sovBbab5cz43inMmZzaBjeEl2cmr84z+kwj6nmufoDTIGK+zj1bBde/zIJm9TEL7rSZQwhj9jLXs7cDncVuK8q6ihRp5fBqmqu+dVFqsLhEC8lSJHkKEPxwEXnp065ep5MT4kewONJDxrbALb828ti0TFHRq/wjBn7Xr4UZ4QPX4upk5KfLcx6xlgbaYYLP19PGWWf14VuhaonSBUwvC9ylcBED/NliTjKrcGl/LS83I0mR+FxpEXPsn6WpqRcfPjt5FMvaQs698n0bsWbEl3FVeLjfnVPn1fOPvDrsszCJ6mkz0kPx1sTtZxNhnTMGXfs09tmCZN5YHrWOPS8uX8DiGCWhBoarA4AAAAASUVORK5CYII=);
  background-size: 100% 100%;
  color: #fff !important;
}
.badge_grad_new:hover a {
  color: #fff !important;
}
.sale_deta_name_orb {
  font-family: "Orbitron", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  color: #fa542c;
}

.progress_bar_div {
  position: relative;
}
.per_v_po {
  position: absolute;
  margin-bottom: 0px;
  color: #fff !important;
  font-size: 10px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
}
.video_div {
  width: 100%;
  height: 350px;
  border-radius: 30px;
  border: 6px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.video_div iframe {
  border-radius: 25px;
}

.max_cont_div {
  max-width: max-content;
}
.text_blk_icon {
  cursor: pointer;
}
.apexcharts-canvas {
  margin: 80px auto 30px auto;
  position: relative;
}
.apexcharts-canvas::before {
  content: "";
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../images/chartimg.png");
  background-size: 80px 80px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  z-index: 1;
}
.bg_legend {
  width: 220px;
  min-width: 220px;
  height: 40px;
}
.legend_lable_text {
  color: #fff;
  font-family: "Orbitron", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 20px;
}

.hr_black {
  border-top-color: #fff00096 !important;
}
/* .text_blk_icon
 {
  color:#fff;
  cursor: pointer;
 }
 .text_blk_icon:hover
 {
  color:#fff;
 } */

.cur_badge_black {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.cur_badge_black.active {
  background: #fff !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.text-danger {
  color: #5b1018 !important;
  font-weight: 500;
}

.load_trend_skelton {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100px;
  height: 8px;
}

@keyframes skeleton-loading {
  0% {
    background-color: #2c2d30;
  }
  100% {
    background-color: #5d4943;
  }
}
.modal-body.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
